import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'
import cn from 'classnames'

import './styles.scss'

import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { VendorsDragList } from '../VendorsDragList/VendorsDragList'
import { useCallback } from 'react'
import { TOptionsWithNameResponse } from '../../../../../constants'

interface IProps {
  isSinglePagePreview?: boolean
}

const SurveyPreview = ({ isSinglePagePreview = false }: IProps) => {
  const ability = useAbility<any>(AbilityContext)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()
  const rfpVotingContext = useRFPVotingContext()

  const { data } = rfpDetailsContext.state
  const { isSurveySubmitted } = rfpSurveyContext.state
  const {
    activeSelectionList,
    activeCategoryIndex,
    activeCategoryName,
    pagesCount,
    fullListPreview
  } = rfpCreateSurveyContext.state
  const { setActiveSelectionList } = rfpCreateSurveyContext.actions
  const { isVotingInProgress } = rfpVotingContext.state

  const isVotingDisabled =
    ability.cannot(ACTIONS.VOTE, subject(SUBJECTS.SURVEY, { ...data })) ||
    !isVotingInProgress ||
    isSurveySubmitted

  const getCategoryView = useCallback(
    (
      categoryName: string,
      activeIdx: number,
      vendors: TOptionsWithNameResponse[]
    ) => (
      <>
        <div className="flex row survey-category-wrapper p-16 full-width space-between mb-8 mt-32">
          <div>
            <Typography.Label className="mb-4 label-color">
              Contract category
            </Typography.Label>
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
              {categoryName}
            </Typography.Body1>
          </div>
          <div>
            <Typography.Body2 className="label-color">
              {`${activeIdx + 1}/${pagesCount}`}
            </Typography.Body2>
          </div>
        </div>
        <div className={cn({ 'survey-preview__ratings': !isSurveySubmitted })}>
          <VendorsDragList
            vendorsList={vendors}
            setVendorsList={setActiveSelectionList}
            isDisabled={isVotingDisabled}
          />
        </div>
      </>
    ),
    [isSurveySubmitted, isVotingDisabled, pagesCount, setActiveSelectionList]
  )

  return (
    <div className="rfp-create-survey-preview survey-preview">
      {isSurveySubmitted || (
        <Typography.Body1>
          Please, rank vendors by dragging them up and down
        </Typography.Body1>
      )}
      {isSinglePagePreview
        ? fullListPreview.vote.map((listItem, idx) =>
            getCategoryView(
              listItem.category.name,
              idx,
              listItem.ranked_vendors.map((item) => item.vendor)
            )
          )
        : getCategoryView(
            activeCategoryName,
            activeCategoryIndex,
            activeSelectionList
          )}
    </div>
  )
}

export default SurveyPreview
