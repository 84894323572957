import cn from 'classnames'
import moment from 'moment'

import './styles.scss'

import { Button, BUTTON_TYPES } from 'components/Button'
import { Tooltip } from 'components/Tooltip/Tooltip'
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Steps } from 'components/Steps/Steps'
import { durationAsString } from 'helper/dates'
import { ReactComponent as Timer16 } from 'assets/svg/Timer16.svg'
import { TContainerProps } from '../types'

const Details = ({
  className,
  title,
  subtitle,
  breadcrumbs,
  steps,
  currentStep,
  status,
  actions,
  menu,
  expiresAt,
  onClickTimer,
  previentTimerInteraction,
  children,
  reasonComponent
}: TContainerProps) => (
  <div
    className={cn('container', className, {
      'container-with-nav': !!status || !!breadcrumbs?.length || !!steps?.length
    })}
  >
    <div className="container__header">
      {(!!breadcrumbs?.length || !!steps?.length || !!status) && (
        <div className="container__nav">
          {!!breadcrumbs?.length && <Breadcrumb items={breadcrumbs} />}
          {!!steps?.length && <Steps steps={steps} current={currentStep} />}
          {(!!status || !!expiresAt) && (
            <div className="container__status">
              {!!expiresAt && (
                <Tooltip
                  title="Left till review completion"
                  placement="bottom"
                  align={{
                    offset: [0, -25]
                  }}
                >
                  <Button
                    icon={<Timer16 />}
                    type={BUTTON_TYPES.LINK}
                    onClick={onClickTimer}
                    className={cn('container__button', {
                      'container__timer-button--pointer-events_none':
                        previentTimerInteraction
                    })}
                  >
                    {durationAsString(moment(), expiresAt)} left
                  </Button>
                </Tooltip>
              )}
              {!!status && status}
            </div>
          )}
        </div>
      )}
      <div className="container__title-wrapper">
        <div className="container__actions-wrapper">
          {title && <div className="container__title">{title}</div>}
          {!!actions && window.innerWidth > 600 && (
            <div className="container__actions">{actions}</div>
          )}
        </div>
        {subtitle && (
          <div className="container__subtitle">
            <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
              {subtitle}
            </Typography.Headline6>
          </div>
        )}
      </div>
      {reasonComponent && (
        <div className="container__reason-component"> {reasonComponent}</div>
      )}
      {menu && <div className="container__menu">{menu}</div>}
    </div>
    <div className="container__content">{children}</div>
    {!!actions && window.innerWidth <= 600 && (
      <div className="container__actions__mobile mt-32">{actions}</div>
    )}
  </div>
)

export default Details
