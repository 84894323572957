import { RFP_STATUSES } from '../constants'
import { TRFPBiddingData, TRFPDetailsResponse } from './types'

export const initialUpdateData = {
  biddingUpdate: false,
  surveyUpdate: false
}

export const initialState: TRFPDetailsResponse = {
  uuid: '',
  name: '',
  default_documents: [],
  expire_at: '',
  number: '',
  timelines: [],
  status: RFP_STATUSES.CREATED,
  creator: {
    uuid: '',
    first_name: '',
    last_name: '',
    role: null!,
    avatar: null!
  },
  vendor_contract_steward: {
    uuid: '',
    first_name: '',
    last_name: '',
    role: null!,
    avatar: null!
  },
  health_systems: [],
  contract_categories: [],
  autogenerated_default_documents: [],
  community_responsibles: [],
  commons_responsibles: [],
  vendor_responsibles: [],
  creation_type: 'community',
  participation_type: 'clinically_led_self',
  has_unset_categories: false
}

export const initialBiddingData: TRFPBiddingData = {
  document_ids: [],
  acceptance_status: '',
  documents: []
}

export const RFP_DETAILS_BREADCRUMBS = (rfpName) => [
  {
    children: 'Bidding',
    href: '/bidding'
  },
  {
    children: rfpName
  }
]

export const RFP_DETAILS_TABS = [
  {
    key: '0',
    label: 'General'
  },
  {
    key: '1',
    label: 'Item list'
  },
  {
    key: '2',
    label: 'Vendors'
  },
  {
    key: '3',
    label: 'Facility users'
  },
  {
    key: '4',
    label: 'Voting'
  },
  {
    key: '5',
    label: 'Vendor users'
  },
  {
    key: '6',
    label: 'Bidding'
  },
  {
    key: '7',
    label: 'Results'
  }
]

export const RFP_TYPE_OPTIONS = [
  { label: 'Community RFP', value: 'community' },
  { label: 'Health System RFP', value: 'health_system' }
]
export const PARTICIPATION_TYPE_OPTIONS = [
  { label: 'Clinically led self', value: 'clinically_led_self' },
  { label: 'Clinically led central', value: 'clinically_led_central' }
]

export const initialRfp = {
  participation_type: 'clinically_led_self',
  creation_type: 'community',
  health_systems: []
}

export const initialRfpCommons = {
  health_systems: [],
  participation_type: '',
  creation_type: ''
}
