import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table } from '../../components/Table'
import { AddCCPopup } from './AddCCPopup/AddCCPopup'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { setLoading } from '../../redux/store/common/slice'
import { columns } from './constants'
import { changeCommunityLevel, getUserContractCategories } from './api'
import { BTN_TXT } from '../../constants'
import { DEFAULT_PAGE } from '../../components/Table/constants'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import {
  ChangeCommunityLevelFunc,
  UserContractCategoriesParams,
  UserContractCategoriesProps,
  UserContractCategoriesRequestData,
  UserContractCategory
} from './types'

export const UserContractCategories = ({
  role,
  userId
}: UserContractCategoriesProps) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [tableData, setTableData] = useState<UserContractCategory[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [sortParams, setSortParams] =
    useState<UserContractCategoriesRequestData>()
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const dispatch = useDispatch()

  useEffect(() => {
    getTableData({
      ...sortParams,
      page: pageInfo
    })
  }, [])

  const onChangeCommunityLevel: ChangeCommunityLevelFunc = async (
    ccId,
    value
  ) => {
    try {
      dispatch(setLoading(true))
      await changeCommunityLevel(userId, ccId, {
        community_level: value
      })
      await onUpdateCCList()
    } catch (e) {
      dispatch(setLoading(false))
    }
  }

  const tableColumns = useMemo(
    () => (role ? columns(role, onChangeCommunityLevel) : []),
    [role]
  )

  const getTableData = async ({
    sortField,
    sortOrder,
    page
  }: UserContractCategoriesRequestData) => {
    try {
      dispatch(setLoading(true))
      const params: UserContractCategoriesParams = {}
      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }
      const dataPage = page ? page : pageInfo
      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      const { data } = await getUserContractCategories(userId as string, params)
      setTableData(
        data.results.map((i) => ({
          ...i,
          key: i.uuid
        }))
      )
      setTotalNumber(data.count)
      if (firstLoad) {
        setFirstLoad(false)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleChangePageSize = async (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    await getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const handleTableChange = async (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    await getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const onUpdateCCList = async () =>
    await getTableData({
      ...sortParams
    })

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const onOpenAddModal = () => setIsAddModalOpen(true)

  return (
    <div className="user-contract-categories">
      <div className="row justify-end mb-4">
        {!!selectedRowKeys.length ? (
          <Button
            type={BUTTON_TYPES.PRIMARY}
            icon={<Delete16 />}
            upperCase
            danger
          >
            {BTN_TXT.DELETE}
          </Button>
        ) : (
          <Button
            onClick={onOpenAddModal}
            type={BUTTON_TYPES.PRIMARY}
            upperCase
          >
            {BTN_TXT.ASSIGN_CONTRACT_CATEGORY}
          </Button>
        )}
      </div>
      {!firstLoad && (
        <Table
          dataSource={tableData}
          className="user-contract-categories-table"
          columns={tableColumns}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          createButton={{
            buttonText: BTN_TXT.ASSIGN_CONTRACT_CATEGORY,
            availability: true,
            action: onOpenAddModal
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectRow
          }}
        />
      )}
      <AddCCPopup
        isOpen={isAddModalOpen}
        userId={userId}
        setIsOpen={setIsAddModalOpen}
        onUpdateCCList={onUpdateCCList}
      />
    </div>
  )
}
