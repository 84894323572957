import { ColumnsType } from 'antd/es/table'
import { Sorter } from '../Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { ICCList } from './types'
import { BTN_TXT } from '../../constants'
import { Link } from '../Link'
import { routes } from '../../router'

export const columns: ColumnsType<ICCList> = [
  {
    dataIndex: 'name',
    key: 'name',
    width: 496,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<ICCList>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Category
      </Sorter>
    ),
    render: (_, { name }) => name,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'action',
    key: 'action',
    title: '',
    width: 116,
    render: (_, row) => (
      <Link
        to={`${routes.contracts}${routes.contractCategories}/new?contract_category=${row.uuid}`}
      >
        {BTN_TXT.SET_CATEGORY}
      </Link>
    )
  }
]
