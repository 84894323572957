import Fetcher from '../../../../services/Fetcher'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../../../constants'
import {
  HealthSystemDetailsData,
  TAdminFeesOption,
  TAdminFeeUpdateRequest,
  TNationalGpoUpdateRequest
} from './types'

const fetcher = new Fetcher({})

export const getHealthSystemDetails = async (uuid: string) =>
  fetcher.request<unknown, HealthSystemDetailsData>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/`,
    method: HTTP_METHODS.GET
  })

export const updateNationalGpoName: TNationalGpoUpdateRequest = async (
  uuid,
  data
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/${API_DICTIONARY.NATIONAL_GPO}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getAdminFeesHSOptions = async (uuid: string) =>
  fetcher.request<unknown, ResponseWithPagination<TAdminFeesOption>>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/${API_DICTIONARY.OPTIONS}${API_DICTIONARY.ADMIN_SPLIT_FEE}`,
    method: HTTP_METHODS.GET
  })

export const updateAdminFee: TAdminFeeUpdateRequest = async (uuid, data) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/${API_DICTIONARY.ADMIN_SPLIT_FEE}`,
    method: HTTP_METHODS.POST,
    data
  })
