import { Banner } from '../../../../components/Banner/Banner'

type TProps = {
  onOpen: () => void
}
const UnsetCCBanner = ({ onOpen }: TProps) => {
  return (
    <Banner
      text={
        'Please note that the setup for these categories will need to be completed later.'
      }
      className="mt-24 mb-24"
      button={{
        buttonText: 'see unset categories',
        onButtonClick: onOpen
      }}
    />
  )
}

export default UnsetCCBanner
