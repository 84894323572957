import Typography from 'components/Typography/Typography'
import { TAdminFeesOption, THandleAdminFeesProps } from './types'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { setLoading } from '../../../../redux/store/common/slice'
import { getAdminFeesHSOptions, updateAdminFee } from './api'
import { notification } from '../../../../components/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from '../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import { Input } from '../../../../components/Input'
import Avatar from 'antd/lib/avatar'
import { LeadByIndicator } from '../../../../components/LeadByIndicator'
import { LED_BY_VALUE } from '../../../../components/LeadByIndicator/constants'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { ReactComponent as EditIcon } from 'assets/svg/EditBlue16.svg'
import { canEditFee, getContractTypeName } from './constants'
import { getUser } from '../../../../redux/store/user/getters'
import { ability, ACTIONS, ROLES, SUBJECTS, Can } from '../../../Permission'
import { twoDecimalParser } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { ReactComponent as ChevronUp16 } from 'assets/svg/ChevronUp16.svg'

const HandleAdminSplitFees = ({
  admin_fees,
  getDetails
}: THandleAdminFeesProps) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { id: hsId } = useParams()

  const [adminFee, setAdminFee] = useState<string>('')
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [adminFeesOptions, setAdminFeesOptions] = useState<TAdminFeesOption[]>(
    []
  )

  const onCloseModal = () => setShowPopup(false)

  const onOpenModal = () => {
    const ledBy = adminFeesOptions.find((option) => option.editable)?.led_by
    setAdminFee(admin_fees?.find((fee) => fee.led_by == ledBy)?.value || '')
    setShowPopup(true)
  }

  const canViewAdminFees = useMemo(() => {
    const canView = ability.can(ACTIONS.VIEW, SUBJECTS.ADMIN_SPLIT_FEE)

    if (
      user.role === ROLES.VICE_PRESIDENT ||
      user.role === ROLES.CONTRACT_STEWARD
    ) {
      return canView && hsId === user.health_system
    }

    return canView
  }, [hsId, user.health_system, user.role])

  const getAdminFeesOptions = useCallback(async () => {
    if (!hsId) return
    try {
      const resp = await getAdminFeesHSOptions(hsId)
      setAdminFeesOptions(resp?.data?.results)
    } catch (err) {
      console.error(err)
    }
  }, [hsId])

  const onSubmitAdminFees = useCallback(async () => {
    dispatch(setLoading(true))
    if (!hsId) return
    const ledBy = adminFeesOptions.find((option) => option.editable)?.led_by
    if (!ledBy) return
    try {
      const resp = await updateAdminFee(hsId, {
        led_by: ledBy,
        value: adminFee
      })
      if (!!resp?.data?.value) {
        getDetails(hsId)
        notification.success({
          message: `Admin fee split has been updated`
        })
      }
    } catch (err) {
      console.error(err)
    } finally {
      onCloseModal()
      setAdminFee('')
      dispatch(setLoading(false))
    }
  }, [hsId, adminFee])

  useEffect(() => {
    if (hsId) {
      getAdminFeesOptions()
    }
  }, [hsId])

  const adminFeeCommunity = useMemo(() => {
    return admin_fees?.find((fee) => fee.led_by === LED_BY_VALUE.COMMUNITY)
      ?.value
  }, [admin_fees])
  const adminFeeCommons = useMemo(() => {
    return admin_fees?.find((fee) => fee.led_by === LED_BY_VALUE.COGNUS_COMMONS)
      ?.value
  }, [admin_fees])
  const adminFeeGPO = useMemo(() => {
    return admin_fees?.find((fee) => fee.led_by === LED_BY_VALUE.NATIONAL_GPO)
      ?.value
  }, [admin_fees])

  const titleForModal = useMemo(() => {
    const ledBy = adminFeesOptions.find((option) => option.editable)?.led_by
    const value = admin_fees?.find((fee) => fee.led_by == ledBy)?.value || 0
    return `${!Number(value) ? 'Add' : 'Edit'} admin fees split`
  }, [adminFeesOptions, admin_fees])

  return !canViewAdminFees ? null : (
    <>
      <div className="admin-split-fees-wrapper">
        <Typography.Headline6>Admin fees split</Typography.Headline6>
        <div className="row mt-16 gap-40">
          {(!!Number(adminFeeCommons) ||
            canEditFee(adminFeesOptions, LED_BY_VALUE.COGNUS_COMMONS)) && (
            <div className="row gap-12">
              <Avatar.Group>
                <LeadByIndicator value={LED_BY_VALUE.COGNUS_FOR_COMMUNITY} />
                <LeadByIndicator value={LED_BY_VALUE.COGNUS_COMMONS} />
              </Avatar.Group>
              <FieldWithLabel title={'Сognus Contracts split,%'}>
                {`${
                  !!Number(adminFeeCommons)
                    ? adminFeeCommons + '%'
                    : 'Not provided'
                }`}
              </FieldWithLabel>
              {canEditFee(adminFeesOptions, LED_BY_VALUE.COGNUS_COMMONS) && (
                <div className="gpo-edit-icon" onClick={onOpenModal}>
                  <EditIcon />
                </div>
              )}
            </div>
          )}
          <Can I={ACTIONS.VIEW} a={SUBJECTS.COMMUNITY_ADMIN_SPLIT_FEE}>
            {(!!Number(adminFeeCommunity) ||
              canEditFee(adminFeesOptions, LED_BY_VALUE.COMMUNITY)) && (
              <div className="row gap-12">
                <LeadByIndicator value={LED_BY_VALUE.COMMUNITY} />
                <FieldWithLabel title={'Community Contracts split,%'}>
                  {`${
                    !!Number(adminFeeCommunity)
                      ? adminFeeCommunity + '%'
                      : 'Not provided'
                  }`}
                </FieldWithLabel>
                {canEditFee(adminFeesOptions, LED_BY_VALUE.COMMUNITY) && (
                  <div className="gpo-edit-icon" onClick={onOpenModal}>
                    <EditIcon />
                  </div>
                )}
              </div>
            )}
          </Can>
          {(!!Number(adminFeeGPO) ||
            canEditFee(adminFeesOptions, LED_BY_VALUE.NATIONAL_GPO)) && (
            <div className="row gap-12">
              <LeadByIndicator value={LED_BY_VALUE.NATIONAL_GPO} />
              <FieldWithLabel title={'GPO Contracts split,%'}>
                {`${
                  !!Number(adminFeeGPO) ? adminFeeGPO + '%' : 'Not provided'
                }`}
              </FieldWithLabel>
              {canEditFee(adminFeesOptions, LED_BY_VALUE.NATIONAL_GPO) && (
                <div className="gpo-edit-icon" onClick={onOpenModal}>
                  <EditIcon />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <Popup
          title={titleForModal}
          className="contract-categories__categories-popup"
          visible={showPopup}
          onCancel={onCloseModal}
          footer={
            <>
              <Button
                onClick={onCloseModal}
                type={BUTTON_TYPES.DEFAULT}
                upperCase
              >
                {BTN_TXT.CANCEL}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={!adminFee?.toString()}
                onClick={onSubmitAdminFees}
                upperCase
              >
                {BTN_TXT.SAVE}
              </Button>
            </>
          }
        >
          <Input.Number
            propsItem={{
              label: `${getContractTypeName(user.role)} Contracts split,%`
            }}
            className="admin-split-fee-input spinner-input-number"
            propsInputNumber={{
              value: adminFee,
              onChange: (e) => {
                setAdminFee(e as string)
              },
              controls: {
                downIcon: <ChevronDown16 />,
                upIcon: <ChevronUp16 />
              },
              parser: twoDecimalParser,
              placeholder: '0',
              max: '100',
              min: '0'
            }}
          />
        </Popup>
      )}
    </>
  )
}

export default HandleAdminSplitFees
