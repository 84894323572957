import { Gauge } from '@ant-design/plots'
import moment from 'moment'
import { TSavingsResponce } from '../types'
import '../styles.scss'
import { ThreeDotsMenu } from '../../../components/ThreeDotsMenu'
import ActualSavingMenu from './Menu'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { TOOLTIP } from '../../../constants'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'

export const ActualSavingsChart = ({
  data,
  isMenuVisible
}: {
  data: TSavingsResponce
  isMenuVisible: boolean
}) => {
  const config = {
    gaugeStyle: {
      lineCap: 'round',
      stroke: '#B5D1E8'
    },
    percent: +data?.progress,
    radius: 0.75,
    range: {
      color:
        +data?.progress >= 1 || +data?.progress === 100
          ? '#1A9E3F'
          : data?.progress === 0
          ? '#D8E7F3'
          : '#73A8D3',
      radius: '20%',
      width: 18
    },
    startAngle: Math.PI / 1.25,
    endAngle: 2.2 * Math.PI,
    indicator: undefined,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontFamily: 'Open Sans',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '32px',
          color: '#285F86',
          marginTop: '-50px'
        },
        formatter: () =>
          `$${
            data?.total_expected_savings
              ? currencyFormatter(data?.total_expected_savings)
              : 0
          }`
      },
      content: {
        style: {
          marginTop: '-50px',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '28px',
          color:
            +data?.progress >= 1 || +data?.progress === 100
              ? '#1A9E3F'
              : '#5497C9'
        },
        formatter: () => {
          const number = +data?.progress === 100 ? 100 : data?.progress * 100
          if (typeof number === 'number') {
            return `${parseFloat(number.toFixed(1))}%`
          }
          return '0%'
        }
      }
    }
  }

  return (
    <div className="charts_actual-savings_chart-container">
      <div className="row space-between align-start">
        <div className="charts_titles">
          <div className="charts_title row align-baseline gap-12">
            Actual savings{' '}
            {data?.inaccurate_data_warning && (
              <Tooltip title={TOOLTIP.ACTUAL_SAVINGS_TOOLTIP}>
                <div className="charts_title__icon row align-center justify-center">
                  <WarningIcon />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="charts_subtitle">
            01/01/{moment().format('YYYY')} - 12/31/{moment().format('YYYY')}
          </div>
        </div>
        {isMenuVisible && (
          <Can I={ACTIONS.VIEW} a={SUBJECTS.ACTUAL_SAVINGS_MENU}>
            <ThreeDotsMenu className="actual-saving-menu">
              <ActualSavingMenu />
            </ThreeDotsMenu>
          </Can>
        )}
      </div>
      <div className="charts_actual-savings_chart-inner">
        <Gauge {...(config as any)} className="charts_actual-savings_chart" />
        <div className="charts_actual-savings">
          <div
            className="charts_actual-savings_start"
            data-testid="actualSavingsStartLabel"
          >
            $0
          </div>
          <div
            className="charts_actual-savings_end"
            data-testid="actualSavingsEndLabel"
          >
            {+data?.target_sum >= 0
              ? '$' + currencyFormatter(data?.target_sum)
              : '-'}
          </div>
        </div>
      </div>
    </div>
  )
}
