import {
  IRFPCCOptions,
  IRFPSurveyCreateRequestPayload,
  IRFPSurveyPayload,
  IRFPVendorCCPayload,
  RFPStakeholderVoteResponseData
} from '../types'
import { TOptionsWithNameResponse } from '../../../../constants'

export const getUniqueValues = (arr: string[]) => Array.from(new Set(arr))

// adjusting BE response structure to conform saved values response
export const parseSurveyToResultsFormat = (
  survey: IRFPSurveyPayload['survey']
): RFPStakeholderVoteResponseData => {
  const parsedQuestionsData: RFPStakeholderVoteResponseData = { vote: [] }
  const surveyCategories = survey.flatMap((item) => item.category) ?? []
  const uniqueCategories = getUniqueValues(
    surveyCategories.map((item) => item.uuid)
  ).map((categoryId) =>
    surveyCategories.find((category) => category.uuid === categoryId)
  )
  uniqueCategories.forEach((category) => {
    if (category) {
      const vendors: TOptionsWithNameResponse[] = []
      survey.forEach((item) => {
        if (item.category.map((cat) => cat.uuid).includes(category.uuid)) {
          vendors.push(item.vendor)
        }
      })
      if (vendors.length) {
        parsedQuestionsData.vote.push({
          category: {
            id: category.uuid,
            name: category.name
          },
          ranked_vendors: vendors
            .sort((a, b) => b.name.localeCompare(a.name))
            .map((vendor, grade) => ({
              vendor,
              grade
            }))
        })
      }
    }
  })
  return parsedQuestionsData
}

export const parseIdValToNameUuid = (
  object: IRFPVendorCCPayload
): TOptionsWithNameResponse => ({
  name: object.value,
  uuid: object.id
})

// parsing selected vendor values to survey preview
export const vendorCCValuesToSurveyFormat = (
  localSelectedPickersValues: Partial<IRFPSurveyCreateRequestPayload>[],
  localSelectedVendors: IRFPVendorCCPayload[],
  contractCategories: IRFPCCOptions
): RFPStakeholderVoteResponseData =>
  parseSurveyToResultsFormat(
    localSelectedPickersValues
      .map((selectedValue) => {
        if (selectedValue.vendor) {
          const vendorItem = localSelectedVendors.find(
            (vendor) => selectedValue.vendor === vendor.id
          )
          if (vendorItem) {
            const selectedCategories = (
              contractCategories[selectedValue.vendor] ?? []
            ).filter((cc) => selectedValue.category?.includes(cc.id))
            return {
              category: selectedCategories.map((category) =>
                parseIdValToNameUuid(category)
              ),
              vendor: parseIdValToNameUuid(vendorItem)
            }
          }
        }
      })
      .filter((item) => item !== undefined) as IRFPSurveyPayload['survey']
  )
