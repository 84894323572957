import { useLayoutEffect, useMemo } from 'react'
import { subject } from '@casl/ability'

import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import SurveyPreview from 'features/RFP/RFPSurvey/CreateSurvey/SurveyPreview/SurveyPreview'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { Can, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { safeString } from 'helper/common'

const SubmitSurveyPopup = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()
  const { submitSurveyPopup } = useRFPDetailsPopupContext()

  const { data } = rfpDetailsContext.state
  const { isSurveySubmitted } = rfpSurveyContext.state
  const { visible, title } = submitSurveyPopup.state

  const {
    handelCancelSurveyVoting,
    onCategorySwitch,
    checkBeforeForwardSwitch
  } = rfpCreateSurveyContext.actions

  const {
    isNextButtonEnabled,
    isPrevButtonEnabled,
    isCancelButtonEnabled,
    isSubmitButtonEnabled
  } = rfpCreateSurveyContext.state

  useLayoutEffect(() => {
    submitSurveyPopup.actions.setState((prevState) => ({
      ...prevState,
      title: `${safeString(data.name)} survey`
    }))
  }, [data.name, submitSurveyPopup.actions])

  const Footer = useMemo(
    () => (
      <>
        {isCancelButtonEnabled && (
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={handelCancelSurveyVoting}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
        )}
        {isPrevButtonEnabled && (
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={() => onCategorySwitch('prev')}
            upperCase
          >
            {BTN_TXT.PREVIOUS}
          </Button>
        )}
        {isNextButtonEnabled && (
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={checkBeforeForwardSwitch}
            upperCase
          >
            {BTN_TXT.NEXT}
          </Button>
        )}
        <Can I={ACTIONS.SUBMIT} a={subject(SUBJECTS.SURVEY, { ...data })}>
          {isSubmitButtonEnabled && !isSurveySubmitted && (
            <Button
              onClick={checkBeforeForwardSwitch}
              type={BUTTON_TYPES.PRIMARY}
              htmlType="submit"
              form="rfp-timeline-form-id"
              upperCase
            >
              {BTN_TXT.SUBMIT_SURVEY}
            </Button>
          )}
        </Can>
      </>
    ),
    [
      checkBeforeForwardSwitch,
      data,
      handelCancelSurveyVoting,
      isCancelButtonEnabled,
      isNextButtonEnabled,
      isPrevButtonEnabled,
      isSubmitButtonEnabled,
      isSurveySubmitted,
      onCategorySwitch
    ]
  )

  return (
    <Popup
      className="rfp-create-survey-popup"
      visible={visible}
      onCancel={handelCancelSurveyVoting}
      title={title}
      width={910}
      footer={Footer}
    >
      <SurveyPreview />
    </Popup>
  )
}

export default SubmitSurveyPopup
