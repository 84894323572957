import { IContractDetails } from 'features/ContractDetails/types'
import { STATUS_TYPES_CLASSNAMES } from 'components/Status'
import { contractDetailsRoute } from 'features/ContractDetails/ContractDetailsRouter/routes'
import { REBATE_CONDITIONS_TYPE } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'

export const CONTRACT_STATUS = {
  CONTRACTING: 'contracting',
  OUT_FOR_SIGNATURE: 'out_for_signature',
  PENDING_FOR_START: 'pending_for_start', // ?
  EXECUTED: 'pending_for_start',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  TERMINATED: 'terminated',
  DECLINED: 'declined'
} as const

export const CONTRACT_STATUS_NAME = {
  [CONTRACT_STATUS.CONTRACTING]: 'Contracting',
  [CONTRACT_STATUS.OUT_FOR_SIGNATURE]: 'Out for signature',
  [CONTRACT_STATUS.EXECUTED]: 'Executed',
  [CONTRACT_STATUS.PENDING_FOR_START]: 'Executed',
  [CONTRACT_STATUS.ACTIVE]: 'Active',
  [CONTRACT_STATUS.EXPIRED]: 'Expired',
  [CONTRACT_STATUS.TERMINATED]: 'Terminated',
  [CONTRACT_STATUS.DECLINED]: 'Declined'
} as const

export const CONTRACT_STATUS_CLASSNAME = {
  [CONTRACT_STATUS.CONTRACTING]: STATUS_TYPES_CLASSNAMES.GRAY,
  [CONTRACT_STATUS.OUT_FOR_SIGNATURE]: STATUS_TYPES_CLASSNAMES.YELLOW,
  [CONTRACT_STATUS.EXECUTED]: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  [CONTRACT_STATUS.ACTIVE]: STATUS_TYPES_CLASSNAMES.GREEN,
  [CONTRACT_STATUS.EXPIRED]: STATUS_TYPES_CLASSNAMES.BLACK,
  [CONTRACT_STATUS.TERMINATED]: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  [CONTRACT_STATUS.DECLINED]: STATUS_TYPES_CLASSNAMES.LIGHTRED,
  [CONTRACT_STATUS.PENDING_FOR_START]: STATUS_TYPES_CLASSNAMES.LIGHTBLUE
}

export const CONTRACT_DETAILS_TABS = [
  {
    key: contractDetailsRoute.general,
    label: 'General'
  },
  {
    key: contractDetailsRoute.facilityUsers,
    label: 'Facility users'
  },
  {
    key: contractDetailsRoute.hsList,
    label: 'HS list'
  },
  {
    key: contractDetailsRoute.itemList,
    label: 'Item list'
  },
  {
    key: contractDetailsRoute.rebateConditions,
    label: 'Rebate conditions'
  }
]

export const INITIAL_CONTRACT_DETAILS: IContractDetails = {
  amendment_document: '',
  admin_fee: '',
  community: {
    uuid: '',
    name: '',
    description: ''
  },
  community_responsibles: [],
  contract_category_name: '',
  creator: {
    uuid: '',
    first_name: '',
    last_name: '',
    role: null!,
    avatar: null
  },
  payment_term_days: null,
  admin_payment_frequency: null,
  start_date: null,
  pricing_effective_date: null,
  finish_date: null,
  primary_finish_date: null,
  name: '',
  number: '',
  rfp: '',
  rfp_name: '',
  status: null!,
  uuid: '',
  vendor: {
    uuid: '',
    name: ''
  },
  vendor_responsibles: [],
  contract_documents: [],
  contract_document_templates: [],
  contract_extension_documents: [],
  contract_attachments: [],
  timelines: [],
  rebate_type: REBATE_CONDITIONS_TYPE.NO_REBATE,
  rebate: null,
  prs: '',
  prs_name: '',
  creation_type: 'community',
  commons_responsibles: [],
  led_by: 'community'
}

export const POSSIBLE_BREADCRUMBS_CONTRACT = [
  {
    children: 'My contracts',
    href: '/contracts/my_contracts'
  },
  {
    children: 'Contract Pipeline',
    href: '/contracts/contract_pipeline'
  }
]

export const CONTRACT_DETAILS_BREADCRUMBS = (title: string, query) => {
  if (!!query?.type) {
    return [
      {
        children: 'Contracts',
        href: '/contracts'
      },
      {
        ...POSSIBLE_BREADCRUMBS_CONTRACT.find((i) =>
          i.href.includes(query.type)
        )
      },
      {
        children: title
      }
    ]
  } else {
    return [
      {
        children: 'Contracts',
        href: '/contracts'
      },
      {
        children: title
      }
    ]
  }
}

export const ATTACHMENTS_FILE_TYPES = {
  'application/*': [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
  ]
}
