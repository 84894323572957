import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, {
  PopupReturnType,
  PopupReturnTypeWithPayload
} from 'hooks/usePopup'
import { DeleteResponseUserPayload } from 'features/RFP/RFPDetails/ResponsibleForRFP/DeleteUserPopup/types'

type ContextProps = {
  sendRFPPopup: PopupReturnType
  sendRFPToCommonsPopup: PopupReturnType
  cancelRFPPopup: PopupReturnType
  changeRFPResponsePopup: PopupReturnType
  createSurveyPopup: PopupReturnType
  awardVendorsPopup: PopupReturnType
  submitSurveyPopup: PopupReturnType
  addResponsibleUserPopup: PopupReturnType
  addResponsibleCommonsUserPopup: PopupReturnType
  resendBidPopup: PopupReturnType
  submitConfirmPopup: PopupReturnType
  rankingUnchangedPopup: PopupReturnType
  unsetCategoriesPopup: PopupReturnType
  deleteResponsibleUserPopup: PopupReturnTypeWithPayload<DeleteResponseUserPayload>
}

const RFPDetailsPopupContext = createContext<ContextProps>({
  sendRFPPopup: null!,
  sendRFPToCommonsPopup: null!,
  cancelRFPPopup: null!,
  changeRFPResponsePopup: null!,
  createSurveyPopup: null!,
  awardVendorsPopup: null!,
  submitSurveyPopup: null!,
  addResponsibleUserPopup: null!,
  addResponsibleCommonsUserPopup: null!,
  resendBidPopup: null!,
  submitConfirmPopup: null!,
  rankingUnchangedPopup: null!,
  deleteResponsibleUserPopup: null!,
  unsetCategoriesPopup: null!
})

const basePopup = (title: string) => ({
  title
})

const RFPDetailsPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const changeRFPResponsePopupProps = usePopup(
    basePopup('Change RFP response?')
  )
  const sendRFPPopupProps = usePopup(basePopup('Bidding steps time'))
  const sendRFPToCommonsPopupProps = usePopup(basePopup('Send to commons?'))
  const cancelRFPPopupProps = usePopup(basePopup('Cancel'))
  const createSurveyPopupProps = usePopup(basePopup('Create survey'))
  const awardVendorsPopupProps = usePopup(basePopup('Vendor selection'))
  const submitSurveyPopupProps = usePopup(basePopup('survey'))
  const resendBidPopupProps = usePopup(basePopup('Resend Bid?'))
  const submitConfirmProps = usePopup(basePopup('Submit survey response?'))
  const rankingUnchangedProps = usePopup(basePopup('Default Ranking Unchanged'))
  const addResponsibleUserPopupProps = usePopup(
    basePopup('Add team members to the RFP')
  )
  const addResponsibleCommonsUserPopupProps = usePopup(
    basePopup('Add responsible for RFP')
  )
  const deleteResponsibleUserPopupProps = usePopup<DeleteResponseUserPayload>(
    basePopup('Delete team member?')
  )
  const unsetCategoriesPopupProps = usePopup(basePopup('Unset categories'))

  const context = useMemo(
    () => ({
      sendRFPPopup: sendRFPPopupProps,
      cancelRFPPopup: cancelRFPPopupProps,
      changeRFPResponsePopup: changeRFPResponsePopupProps,
      createSurveyPopup: createSurveyPopupProps,
      awardVendorsPopup: awardVendorsPopupProps,
      submitSurveyPopup: submitSurveyPopupProps,
      addResponsibleUserPopup: addResponsibleUserPopupProps,
      deleteResponsibleUserPopup: deleteResponsibleUserPopupProps,
      sendRFPToCommonsPopup: sendRFPToCommonsPopupProps,
      resendBidPopup: resendBidPopupProps,
      submitConfirmPopup: submitConfirmProps,
      rankingUnchangedPopup: rankingUnchangedProps,
      addResponsibleCommonsUserPopup: addResponsibleCommonsUserPopupProps,
      unsetCategoriesPopup: unsetCategoriesPopupProps
    }),
    [
      sendRFPPopupProps,
      cancelRFPPopupProps,
      changeRFPResponsePopupProps,
      createSurveyPopupProps,
      awardVendorsPopupProps,
      submitSurveyPopupProps,
      addResponsibleUserPopupProps,
      deleteResponsibleUserPopupProps,
      sendRFPToCommonsPopupProps,
      resendBidPopupProps,
      submitConfirmProps,
      rankingUnchangedProps,
      addResponsibleCommonsUserPopupProps,
      unsetCategoriesPopupProps
    ]
  )

  return (
    <RFPDetailsPopupContext.Provider value={context}>
      {children}
    </RFPDetailsPopupContext.Provider>
  )
}

export const useRFPDetailsPopupContext = () =>
  useContext(RFPDetailsPopupContext)

export default RFPDetailsPopupProvider
