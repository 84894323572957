import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { CellChange, CellLocation, Column, Row } from '@silevis/reactgrid'
import {
  IPickerList,
  IPickersMap,
  ITableItemsData,
  TAvailableColumnIds,
  TBulkRequestPayloadData,
  TCellInfo,
  TCellTypes,
  TErrorResponseTableData,
  TGridTableFormattedData,
  TTableRows
} from '../types'
import {
  availableContractTypes,
  defaultFocusPosition,
  defaultRowData,
  defaultTableCells
} from '../constants'
import {
  checkMissingFieldsValidation,
  getCell,
  getHeaderRow,
  getUpdatedRowInfo,
  parseErrorResponse,
  parseFieldValue,
  parseTableDataToRequest,
  updateRowIdsOnDelete
} from '../helpers'
import { isDeepEqualObjects } from '../../../utils/common'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import {
  fetchAdminPaymentFrequency,
  fetchContractCategoriesForContracts,
  fetchPaymentTerms
} from '../../../pages/CRUDUser/api'
import {
  getAvailableOwnersRequestAsync,
  getVendorContractStewardsRequestAsync,
  getVendorsRequestAsync
} from '../../ContractDetails/api'
import { groupBy } from '../../../utils/common'
import {
  formatOptionIdValue,
  formatOptionNameValue
} from '../../../helper/optionsFormatters'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'
import {
  canSeeMyContracts,
  isCSCanSeeTheSelectionOfTheHSAndCommunityType
} from '../../../helper/common'
import { getToken } from '../../../redux/store/notifications/getters'
import { bulkContractsCreate } from '../api'
import { setLoading } from '../../../redux/store/common/slice'
import { useBottomNotificationsContext } from '../../BottomNotificationProvider/BottomNotificationsContextProvider'
import {
  TNotificationType,
  TNotificationTypeName
} from '../../BottomNotificationProvider/types'
import { notification } from '../../../components/Notification'
import { ROLES_NAMES, VALIDATION_MESSAGES } from '../../../constants'
import { ROLES } from '../../Permission'

type ContextProps = {
  state: {
    rows: TTableRows
    columns: Column[]
    focusPosition: CellLocation
    rowDeleteConfirmationPopupOpened: boolean
    hasPendingChanges: boolean
    errorLabel: string
  }
  actions: {
    onAddNewRow: () => void
    onCellsChanged: (changes: CellChange[]) => void
    onRowDeleteConfirm: () => void
    onRowDeleteCancel: () => void
    onCancel: () => void
    onSubmit: () => void
  }
}

const ContractBulkTableConfigContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const ContractBulkTableConfigContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()
  const registrationId = useSelector(getToken)
  const user = useSelector(getUser)
  const router = useRouter()
  const {
    actions: { openNotification }
  } = useBottomNotificationsContext()

  const tableCells = useMemo(() => {
    switch (user.role) {
      case ROLES.COMMUNITY_PRESIDENT: {
        return defaultTableCells
      }
      case ROLES.CONTRACT_STEWARD: {
        return isCSCanSeeTheSelectionOfTheHSAndCommunityType(user)
          ? defaultTableCells
          : defaultTableCells.filter((i) => i.columnId !== 'contract_type')
      }
      default: {
        return defaultTableCells.filter(
          (i) => i.columnId !== 'contract_responsible'
        )
      }
    }
  }, [defaultTableCells, user])

  const defaultPickerOptions: ITableItemsData = useMemo(() => {
    if (
      user.role === ROLES.COMMONS_VICE_PRESIDENT ||
      user.role === ROLES.COMMONS_CONTRACT_STEWARD
    ) {
      return { ...defaultRowData, contract_type: 'commons' }
    } else if (
      user.role === ROLES.CONTRACT_STEWARD &&
      !isCSCanSeeTheSelectionOfTheHSAndCommunityType(user)
    ) {
      return { ...defaultRowData, contract_type: 'health_system' }
    }
    return defaultRowData
  }, [user])

  const [tableData, setTableData] = useState<ITableItemsData[]>([
    defaultPickerOptions
  ])
  const [errorData, setErrorData] = useState<TErrorResponseTableData>({})
  const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false)
  const [deleteRowId, setDeleteRowId] = useState<
    TGridTableFormattedData['rowId'] | null
  >(null)
  const [focusPosition, setFocusPosition] =
    useState<CellLocation>(defaultFocusPosition)

  // pickers states
  const [vendorPickerOptions, setVendorPickerOptions] = useState<IPickerList[]>(
    []
  )
  const [contractCategoryPickerOptions, setContractCategoryPickerOptions] =
    useState<IPickerList[]>([])
  const [vendorCSPickerOptionsMap, setVendorCSPickerOptionsMap] =
    useState<IPickersMap>({})
  const [contractResponsibleOptionsMap, setContractResponsibleOptionsMap] =
    useState<IPickersMap>({})
  const [adminPaymentFrequencyOptions, setAdminPaymentFrequencyOptions] =
    useState<IPickerList[]>([])
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<IPickerList[]>(
    []
  )

  const formattedTableData = useMemo(
    (): TGridTableFormattedData[] =>
      tableData.map<TGridTableFormattedData>((val, idx) => ({
        ...val,
        rowId: idx
      })),
    [tableData]
  )

  const errorLabel = useMemo(() => {
    // if row contains at least one error - we will have key prop in error object
    const erroredRowsCount = Object.keys(errorData).length
    if (!erroredRowsCount) return ''
    return `${
      erroredRowsCount === 1 ? '1 row' : `${erroredRowsCount} rows`
    } contain empty cells or errors.`
  }, [errorData])

  const onCancel = useCallback(
    () =>
      router.push(
        `${routes.contracts}${
          canSeeMyContracts(user.role) ? '/my_contracts' : ''
        }`
      ),
    [router, user.role]
  )

  const onSubmit = useCallback(() => {
    const errors = checkMissingFieldsValidation(tableData, user)
    if (Object.keys(errors).length) {
      setErrorData(errors)
      return
    }
    setHasPendingChanges(false)
    dispatch(setLoading(true))
    const requestPayload: TBulkRequestPayloadData = {
      contracts: parseTableDataToRequest(tableData, user),
      firebase_registration_id: registrationId
    }
    bulkContractsCreate(requestPayload)
      .then((response) => {
        if (!registrationId) {
          notification.success({
            message: VALIDATION_MESSAGES.BULK_CREATE_SUCCESS
          })
        } else {
          openNotification(
            {
              id: response.data.task_id,
              numberOfItems: requestPayload.contracts.length,
              type: TNotificationType.INFO,
              date: new Date().toISOString()
            },
            TNotificationTypeName.LOADING_CONTRACTS,
            true
          )
        }
        onCancel()
      })
      .catch((err) => setErrorData(parseErrorResponse(err.data)))
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [
    tableData,
    dispatch,
    user.uuid,
    registrationId,
    onCancel,
    openNotification
  ])

  const onAddNewRow = useCallback(
    () => setTableData((prev) => [...prev, defaultPickerOptions]),
    [defaultPickerOptions]
  )

  const onRowDeleteConfirm = useCallback(
    (rowId?: TGridTableFormattedData['rowId']) => {
      const targetRowId = rowId ?? deleteRowId
      if (targetRowId !== null) {
        setTableData(
          formattedTableData.filter((item) => item.rowId !== targetRowId)
        )
        setErrorData(
          (prev) =>
            updateRowIdsOnDelete(prev, targetRowId) as TErrorResponseTableData
        )
        setVendorCSPickerOptionsMap(
          (prev) => updateRowIdsOnDelete(prev, targetRowId) as IPickersMap
        )
        setDeleteRowId(null)
      }
    },
    [formattedTableData, deleteRowId]
  )

  const onRowDeleteCancel = useCallback(() => setDeleteRowId(null), [])

  const rowDeleteConfirmationPopupOpened = useMemo(
    () => deleteRowId !== null,
    [deleteRowId]
  )

  const onRowDelete = useCallback(
    (rowId: TGridTableFormattedData['rowId']) => {
      const deletingRow = formattedTableData.find(
        (item) => item.rowId === rowId
      )
      if (deletingRow) {
        // if row not changed - no need to show confirm popup
        // removing row id and comparing with default empty row
        const deletingRowData: ITableItemsData = { ...deletingRow }
        delete deletingRowData['rowId']
        if (isDeepEqualObjects(deletingRowData, defaultPickerOptions)) {
          onRowDeleteConfirm(rowId)
        } else {
          setDeleteRowId(rowId)
        }
      }
    },
    [
      setDeleteRowId,
      formattedTableData,
      onRowDeleteConfirm,
      defaultPickerOptions
    ]
  )

  const getInitPickersData = useCallback(() => {
    const { contract_type } = defaultPickerOptions
    Promise.all([
      fetchContractCategoriesForContracts(contract_type),
      getVendorsRequestAsync(),
      fetchPaymentTerms(),
      fetchAdminPaymentFrequency()
    ]).then(
      ([
        contractCategoryResponse,
        vendorsResponse,
        paymentTerms,
        adminPaymentFrequency
      ]) => {
        if (vendorsResponse?.data?.results) {
          setVendorPickerOptions(
            vendorsResponse.data.results.map(formatOptionNameValue)
          )
        }
        if (contractCategoryResponse?.data?.results) {
          setContractCategoryPickerOptions(
            contractCategoryResponse.data.results.map(formatOptionNameValue)
          )
        }
        if (paymentTerms?.data?.results) {
          setPaymentTermsOptions(
            paymentTerms.data.results.map(formatOptionIdValue)
          )
        }
        if (adminPaymentFrequency?.data?.results) {
          setAdminPaymentFrequencyOptions(
            adminPaymentFrequency.data.results.map(formatOptionIdValue)
          )
        }
      }
    )
  }, [defaultPickerOptions])

  const getVendorCSPickerData = useCallback(
    (vendor: string, contract_category: string, rowId: TCellInfo['rowId']) => {
      if (vendor && contract_category) {
        getVendorContractStewardsRequestAsync({
          vendors: [vendor],
          contract_categories: [contract_category]
        }).then((response) => {
          if (response?.data?.results) {
            setVendorCSPickerOptionsMap((prev) => ({
              ...prev,
              [rowId]: response.data.results.map((option) => ({
                ...option,
                label: [option.first_name, option.last_name].join(' '),
                value: option.uuid
              }))
            }))
          }
        })
      } else {
        // if any of the fields is empty - need to empty list of vendorCS
        setVendorCSPickerOptionsMap((prev) => ({
          ...prev,
          [rowId]: undefined
        }))
      }
    },
    []
  )

  const getContractResponsibleItems = useCallback(
    async (contract_category: string, rowId: TCellInfo['rowId']) => {
      try {
        if (contract_category) {
          const { data } = await getAvailableOwnersRequestAsync({
            categories: [contract_category]
          })
          if (data?.results) {
            const roleOptions = groupBy('role', data.results)
            setContractResponsibleOptionsMap((prev) => ({
              ...prev,
              [rowId]: Object.keys(roleOptions).map((role) => ({
                label: ROLES_NAMES[role],
                value: role,
                options: data.results
                  .filter((i) => i.role === role)
                  .map((option) => ({
                    ...option,
                    label: `${option.first_name} ${option.last_name}`,
                    value: option.uuid
                  }))
              }))
            }))
          }
        } else {
          setContractResponsibleOptionsMap((prev) => ({
            ...prev,
            [rowId]: undefined
          }))
        }
      } finally {
      }
    },
    []
  )

  const contractTypePickerOptions = useMemo(() => {
    if (user.role === ROLES.COMMUNITY_PRESIDENT) {
      return availableContractTypes.filter((item) => item.value === 'community')
    } else if (
      user.role === ROLES.COMMONS_VICE_PRESIDENT ||
      user.role === ROLES.COMMONS_CONTRACT_STEWARD
    ) {
      return availableContractTypes.filter((item) => item.value === 'commons')
    }
    return availableContractTypes.filter((item) => item.value !== 'commons')
  }, [user])

  const getPickerItemsBasedOnFieldName = useCallback(
    (columnId: TAvailableColumnIds, rowId: TCellInfo['rowId']) => {
      let pickerItems: IPickerList[] | null = []
      switch (columnId) {
        case 'contract_type':
          pickerItems = contractTypePickerOptions
          break
        case 'vendor':
          pickerItems = vendorPickerOptions
          break
        case 'contract_category':
          pickerItems = contractCategoryPickerOptions
          break
        case 'vendor_contract_steward':
          pickerItems = vendorCSPickerOptionsMap[rowId] ?? null
          break
        case 'contract_responsible':
          pickerItems = contractResponsibleOptionsMap[rowId] ?? null
          break
        case 'payment_term_days':
          pickerItems = paymentTermsOptions
          break
        case 'admin_payment_frequency':
          pickerItems = adminPaymentFrequencyOptions
          break
      }
      return pickerItems
    },
    [
      contractTypePickerOptions,
      contractCategoryPickerOptions,
      vendorPickerOptions,
      vendorCSPickerOptionsMap,
      contractResponsibleOptionsMap,
      paymentTermsOptions
    ]
  )

  const refreshCCPickerOptions = useCallback(async (contractType) => {
    await fetchContractCategoriesForContracts(contractType).then(
      (contractCategoryResponse) => {
        if (contractCategoryResponse?.data?.results) {
          setContractCategoryPickerOptions(
            contractCategoryResponse.data.results.map(formatOptionNameValue)
          )
        }
      }
    )
  }, [])

  const onCellsChanged = useCallback(
    (changes: CellChange<TCellTypes>[]) => {
      setHasPendingChanges(true)
      setTableData([
        ...formattedTableData.map((row) => {
          const specificRowChanges = changes.filter(
            (item) => item.rowId === row.rowId
          )
          const changedFields = parseFieldValue(
            getUpdatedRowInfo(specificRowChanges),
            (colId) => getPickerItemsBasedOnFieldName(colId, row.rowId)
          )
          // if contract type was changed - we need to refresh contract categories picker options
          if (changedFields.contract_type) {
            changedFields.contract_category = undefined
            refreshCCPickerOptions(changedFields.contract_type)
          }
          // if changes contain contact category or vendor fields - we need to update vendorCS picker with corresponding options
          if (changedFields.contract_category || changedFields.vendor) {
            const vendor = changedFields.vendor ?? row.vendor
            const contractCategory =
              changedFields.contract_category ?? row.contract_category
            getVendorCSPickerData(vendor, contractCategory, row.rowId)
            changedFields.vendor_contract_steward = undefined
          }
          if (
            changedFields.contract_category &&
            user.role === ROLES.COMMUNITY_PRESIDENT
          ) {
            getContractResponsibleItems(
              changedFields.contract_category,
              row.rowId
            )
            changedFields.contract_responsible = undefined
          }
          if (changedFields.finish_date || changedFields.start_date) {
            const startDate = changedFields.start_date ?? row.start_date
            const endDate = changedFields.finish_date ?? row.finish_date
            if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
              changedFields.finish_date = startDate
            }
          }
          return {
            ...row,
            ...changedFields
          }
        })
      ])
    },
    [
      formattedTableData,
      getPickerItemsBasedOnFieldName,
      getVendorCSPickerData,
      getContractResponsibleItems,
      user.role
    ]
  )

  const rows = useMemo(
    (): TTableRows => [
      getHeaderRow(tableCells.map((cell) => cell.headerText ?? '')),
      ...formattedTableData.map<Row<TCellTypes>>((row) => ({
        rowId: row.rowId,
        height: 40,
        cells: tableCells.map((cell) =>
          getCell({
            cell,
            rowId: row.rowId,
            row,
            errorCellIds: errorData[row.rowId] ?? [],
            focusChanged: setFocusPosition,
            pickerItems: getPickerItemsBasedOnFieldName(
              cell.columnId,
              row.rowId
            ),
            deletePressed:
              formattedTableData.length > 1 ? onRowDelete : undefined
          })
        )
      }))
    ],
    [
      tableCells,
      formattedTableData,
      errorData,
      getPickerItemsBasedOnFieldName,
      onRowDelete
    ]
  )

  const columns = useMemo(
    (): Column[] =>
      tableCells.map((cell) => ({
        columnId: cell.columnId,
        width: cell.width
      })),
    []
  )

  useEffect(() => {
    getInitPickersData()
  }, [getInitPickersData])

  const context = useMemo(
    () => ({
      state: {
        rows,
        columns,
        focusPosition,
        rowDeleteConfirmationPopupOpened,
        hasPendingChanges,
        errorLabel
      },
      actions: {
        onAddNewRow,
        onCellsChanged,
        onRowDeleteConfirm,
        onRowDeleteCancel,
        onCancel,
        onSubmit
      }
    }),
    [
      errorLabel,
      onAddNewRow,
      hasPendingChanges,
      onCellsChanged,
      rows,
      columns,
      focusPosition,
      rowDeleteConfirmationPopupOpened,
      onRowDeleteConfirm,
      onRowDeleteCancel,
      onCancel,
      onSubmit
    ]
  )

  return (
    <ContractBulkTableConfigContext.Provider value={context}>
      {children}
    </ContractBulkTableConfigContext.Provider>
  )
}

export const useContractBulkTableConfigContext = () =>
  useContext(ContractBulkTableConfigContext)

export default ContractBulkTableConfigContextProvider
