import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TCreateContractPipelineErrorType,
  TCreateContractPipelineFormType
} from '../CreateContractPipelineModal/types'
import { Form } from 'antd'
import moment, { Moment } from 'moment/moment'
import {
  createContractPipeline,
  editContractPipeline,
  getContractCategoriesOptions,
  getLedByOptions
} from '../api'
import { TOption } from '../../../components/Select/types'
import { setLoading } from '../../../redux/store/common/slice'
import { AxiosResponse } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import { parsePipelineData } from '../CreateContractPipelineModal/helper'
import { isEqualObjects } from '../../../helper/common'
import { initialStateCreateContractPipelineForm } from '../CreateContractPipelineModal/constants'
import { ICPRtDetails } from 'features/ContractsPipelineDetails/types'
import { useCPRDetailsContext } from '../../ContractsPipelineDetails/Providers/CPRDetailsContextProvider'

const useCreateContractPipelineModal = ({
  successCallback,
  editModeData
}: {
  successCallback?: () => void
  editModeData?: ICPRtDetails
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [form] = Form.useForm<TCreateContractPipelineFormType>()
  const [errors, setErrors] = useState<TCreateContractPipelineErrorType>({})

  const [ledByOptions, setLedByOptions] = useState<TOption[]>([])
  const [contractCategoryNameOptions, setContractCategoryNameOptions] =
    useState<TOption[]>([])

  const [isDiscardChanges, setDiscardChanges] = useState(false)

  const [changedValues, setChangedValues] = useState({})

  const { actions } = useCPRDetailsContext()

  const selectedLedBy = Form.useWatch('led_by', form)

  const getLedBy = useCallback(async () => {
    const results = await getLedByOptions({})
    setLedByOptions(
      results?.data.map((r) => {
        return {
          label: r.value,
          value: r.id
        }
      })
    )
  }, [])

  const getContractCategories = useCallback(
    async (led_by: string) => {
      const results = await getContractCategoriesOptions({
        user: editModeData ? editModeData.responsible?.uuid : user.uuid,
        led_by
      })
      setContractCategoryNameOptions(
        results?.data.results.map((r) => {
          return {
            label: r.name,
            value: r.uuid
          }
        })
      )
    },
    [editModeData]
  )

  const onSubmit = useCallback(
    async (values: TCreateContractPipelineFormType) => {
      dispatch(setLoading(true))
      try {
        const data = parsePipelineData(values, ledByOptions)
        if (editModeData) {
          await editContractPipeline(editModeData.uuid, data).then(() => {
            actions.getContractDetailsAsync()
          })
          setChangedValues({})
        } else {
          await createContractPipeline(data)
          form.resetFields()
        }
        successCallback?.()
      } catch (error) {
        const typedResponse = error as AxiosResponse
        setErrors(typedResponse.data)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, createContractPipeline, form, editModeData]
  )

  const disabledDate = useCallback((date: Moment): boolean => {
    const startDate = moment()
    const endDate = moment().endOf('year').add(4, 'y')
    return !date.isBetween(startDate, endDate, 'days', '[]')
  }, [])

  const onFirstLoad = () => {
    getLedBy()
  }

  useEffect(() => {
    if (selectedLedBy) getContractCategories(selectedLedBy)
    else setContractCategoryNameOptions([])
  }, [selectedLedBy])

  const changesHandler = (newValues) => {
    const newValuesObject = {}
    newValues.forEach((value) => {
      newValuesObject[value.name] = value.value
    })
    setChangedValues({
      ...changedValues,
      ...newValuesObject
    })
  }

  const findChangesHandler = () => {
    if (!Object.keys(changedValues).length) {
      return false
    }
    return !isEqualObjects(
      initialStateCreateContractPipelineForm,
      changedValues
    )
  }

  const formWasChanged = useMemo(() => {
    return Object.keys(changedValues).length > 0
  }, [changedValues])

  return {
    form,
    errors,
    setErrors,
    ledByOptions,
    contractCategoryNameOptions,
    disabledDate,
    onSubmit,
    changesHandler,
    findChangesHandler,
    setChangedValues,
    isDiscardChanges,
    setDiscardChanges,
    onFirstLoad,
    formWasChanged
  }
}

export default useCreateContractPipelineModal
