import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { Container } from 'components/Container/Container'
import { getRfpList } from './api'
import { useDebounce } from 'hooks/hooks'
import useRouter from 'hooks/useRouter'
import { setLoading } from 'redux/store/common/slice'
import { BTN_TXT, PLACEHOLDERS, VALIDATION_MESSAGES } from 'constants/txt'
import { columns } from './constants'
import {
  isCSCanSeeTheSelectionOfTheHSAndCommunityType,
  isTableHasParams
} from 'helper/common'
import { ability, ACTIONS, SUBJECTS, Can, ROLES } from 'features/Permission'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import {
  TRFPListItem,
  TRFPListRequestData,
  TRFPListParams,
  TRFPListFilters,
  TRFPListFiltersFunc
} from './types'
import './styles.scss'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { getUser } from 'redux/store/user/getters'
import { notification } from 'antd'
import CreateRfpModal from './CreateRfpModal'
import { routes } from '../../../router'

export const RFPList = () => {
  const [tableData, setTableData] = useState<TRFPListItem[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TRFPListRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TRFPListFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const { push } = useRouter()
  const user = useSelector(getUser)

  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({
          ...sortParams,
          filters,
          page: { ...DEFAULT_PAGE }
        })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRFPListRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TRFPListParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getRfpList({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)
  const updateRfp = () => {
    if (selectedRowKeys.length === 1) {
      push(`rfp/${selectedRowKeys[0]}`)
    }
  }

  const isCSCanSeeModal = isCSCanSeeTheSelectionOfTheHSAndCommunityType(user)

  const createRfp = () => {
    if (user.role === ROLES.CONTRACT_STEWARD) {
      if (!user.contract_categories?.length) {
        notification.error({ message: VALIDATION_MESSAGES.SM0017 })
        return
      }
      if (isCSCanSeeModal) {
        setIsOpen(true)
      } else {
        push(`${routes.bidding}/rfp/new?typeCreation=health_system`)
      }
      return
    } else if (user.role.includes('commons')) {
      push(`${routes.bidding}/rfp/new?typeCreation=commons`)
      return
    } else if (user.role === ROLES.COMMUNITY_PRESIDENT) {
      push(`${routes.bidding}/rfp/new?typeCreation=community`)
    } else {
      setIsOpen(true)
    }
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TRFPListFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }
  return (
    <Container title="Bidding">
      <div className="table-wrapper rfp-list">
        <div className="table-wrapper__header">
          {!!selectedRowKeys.length ? (
            <Typography.Body1
              className="table-wrapper__select-title"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
          ) : (
            <Input
              className="allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH_RFP_LIST,
                prefix: <Search16 />,
                value: searchValue,
                onChange: (e) => setSearchValue(e.target.value)
              }}
            />
          )}
          <Can I={ACTIONS.CRUD} a={SUBJECTS.RFP}>
            <div className="table-wrapper__header_buttons">
              {selectedRowKeys.length === 1 && (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  icon={<Edit16 />}
                  onClick={updateRfp}
                  upperCase
                >
                  {BTN_TXT.EDIT}
                </Button>
              )}
              {!selectedRowKeys.length && (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  icon={<Plus16 />}
                  onClick={createRfp}
                  upperCase
                >
                  {BTN_TXT.CREATE_RFP}
                </Button>
              )}
            </div>
          </Can>
        </div>
        {!firstLoad && (
          <Table<TRFPListItem>
            dataSource={tableData}
            className="rfp-list-items-table"
            columns={columns({
              applyFilters,
              filters
            })}
            pageSize={pageInfo.pageSize}
            pagination={{
              pageSize: pageInfo.pageSize,
              current: pageInfo.pageNumber,
              total: totalNumber
            }}
            onChangePage={handleChangePageSize}
            onChange={handleTableChange}
            hasSearchOrFilters={isTableHasParams<TRFPListFilters>(
              debouncedSearch,
              filters
            )}
            createButton={{
              buttonText: BTN_TXT.CREATE_RFP,
              availability: ability.can(ACTIONS.CRUD, SUBJECTS.RFP),
              action: createRfp
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: handleSelectRow
            }}
          />
        )}
      </div>
      <CreateRfpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Container>
  )
}
