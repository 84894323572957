import { Popup } from '../Popup'
import { TUnsetCCListTableRequestData } from './types'
import { Table } from '../Table'
import { ICCHealthSystem } from '../../features/ContractCategoryDetails/HSList/types'
import { columns } from './constants'
import { isTableHasParams } from '../../helper/common'
import { useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { DEFAULT_PAGE } from '../Table/constants'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { setLoading } from '../../redux/store/common/slice'
import { TQueryParams } from '../Table/types'

const UnsetCategoriesModal = ({ visible, onCancel, callback }) => {
  const dispatch = useDispatch()
  const [totalNumber, setTotalNumber] = useState(0)
  const [tableData, setTableData] = useState<ICCHealthSystem[]>([])
  const [sortParams, setSortParams] = useState<TUnsetCCListTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const { id } = useParams()

  const tableDataTokenRef = useRef<CancelTokenSource>()
  useEffect(() => {
    getTableData({ ...sortParams, page: { ...DEFAULT_PAGE } })
  }, [])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    page
  }: TUnsetCCListTableRequestData) => {
    dispatch(setLoading(true))
    if (firstLoad) {
      setFirstLoad(false)
    }

    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TQueryParams = {}
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    callback(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp: any) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  useEffect(() => {
    setLoading(false)
  }, [tableData[0]?.uuid as string])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      className="reassign-popup__container"
      title={'Unset categories'}
      footer={null}
      width={676}
    >
      <Table<ICCHealthSystem>
        dataSource={tableData}
        columns={columns}
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        className="rfp-vendors-data-list-table"
        onChangePage={handleChangePageSize}
        onChange={handleTableChange}
        hasSearchOrFilters={isTableHasParams('', false)}
        createButton={undefined}
        rowSelection={undefined}
      />
    </Popup>
  )
}

export default UnsetCategoriesModal
