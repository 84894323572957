import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import './styles.scss'

const SubmitConfirmPopup = () => {
  const { submitConfirmPopup } = useRFPDetailsPopupContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()

  const { handleSubmitSurvey } = rfpCreateSurveyContext.actions

  return (
    <Popup
      {...submitConfirmPopup.state}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={submitConfirmPopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            onClick={handleSubmitSurvey}
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="rfp-timeline-form-id"
            upperCase
          >
            {BTN_TXT.SUBMIT}
          </Button>
        </>
      }
    >
      You will not be able to retract your response. Do you want to submit
      survey response?
    </Popup>
  )
}

export default SubmitConfirmPopup
