import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import './styles.scss'

const RankingUnchangedPopup = () => {
  const { rankingUnchangedPopup } = useRFPDetailsPopupContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()

  const { onCategorySwitch } = rfpCreateSurveyContext.actions

  return (
    <Popup
      {...rankingUnchangedPopup.state}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={rankingUnchangedPopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            onClick={() => {
              onCategorySwitch('next')
              rankingUnchangedPopup.actions.close()
            }}
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="rfp-timeline-form-id"
            upperCase
          >
            {BTN_TXT.PROCEED}
          </Button>
        </>
      }
    >
      No changes have been made for the to the default vendors ranking. Do you
      want to proceed?
    </Popup>
  )
}

export default RankingUnchangedPopup
