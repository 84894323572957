import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import Popup from '../../../components/Popup/Popup'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import Radio, { RadioChangeEvent } from 'antd/lib/radio'
import cn from 'classnames'
import { Space } from 'antd'
import { Fragment, useState } from 'react'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'
import { RFP_TYPE_OPTIONS } from './constantsTxt'
import { TPropsCreateRfpModal } from './types'

const CreateRfpModal = ({ isOpen, setIsOpen }: TPropsCreateRfpModal) => {
  const { push } = useRouter()
  const [value, setValue] = useState('community')

  const onCancel = () => {
    setIsOpen(false)
    setValue('community')
  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const onSend = () => {
    push(`${routes.bidding}/rfp/new?typeCreation=${value}`)
  }
  return (
    <>
      <Popup
        visible={isOpen}
        onCancel={onCancel}
        title={'RFP type '}
        width={453}
        className="run-new-step-modal"
        footer={
          <>
            <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
              {BTN_TXT.CONTINUE}
            </Button>
          </>
        }
      >
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          To create a RFP, please select RFP type:
          <Radio.Group
            onChange={onChange}
            value={value}
            className={cn('radio-group-default', 'mt-16')}
          >
            <Space direction="vertical">
              <>
                {RFP_TYPE_OPTIONS.map((i) => (
                  <Fragment key={i.value}>
                    <Radio value={i.value}>
                      <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
                        {i.label}
                      </Typography.Body1>
                    </Radio>
                    {i.value === RFP_TYPE_OPTIONS[1].value ? (
                      <Typography.Caption className="run-new-step-option__message">
                        RFP and contracts can be used by the selected Health
                        System only
                      </Typography.Caption>
                    ) : null}
                    {i.value === RFP_TYPE_OPTIONS[0].value ? (
                      <Typography.Caption className="run-new-step-option__message">
                        RFP and contracts can be shared with other Health
                        Systems inside Community
                      </Typography.Caption>
                    ) : null}
                  </Fragment>
                ))}
              </>
            </Space>
          </Radio.Group>
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default CreateRfpModal
