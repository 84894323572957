import Typography from '../../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../../components/Typography'
import { Button } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import './styles.scss'
import { TSimilarInvoice } from '../types'

type TProps = {
  similarItem: TSimilarInvoice
  onSetDetailsPressed: () => void
}

const SimilarItemsCard = ({ similarItem, onSetDetailsPressed }: TProps) => {
  return (
    <div className="flex column align-start p-16 mb-8 similar-items-card-container">
      <Typography.Body2 weight={TYPOGRAPHY_WEIGHT.BOLD} className="mb-8">
        {similarItem.item_description ?? '-'}
      </Typography.Body2>
      <Typography.Label className="similar-items-card-container__section-label">
        Category
      </Typography.Label>
      <Typography.Body2 className="mb-8">
        {similarItem.contract_category?.name ?? '-'}
      </Typography.Body2>
      <div className="flex full-width row space-between mb-16">
        <div>
          <Typography.Label className="similar-items-card-container__section-label">
            UNSPSC code
          </Typography.Label>
          <Typography.Body2>{similarItem.unspsc_code ?? '-'}</Typography.Body2>
        </div>
        <div>
          <Typography.Label className="similar-items-card-container__section-label">
            Supplier Item number
          </Typography.Label>
          <Typography.Body2>
            {similarItem.supplier_item_number ?? '-'}
          </Typography.Body2>
        </div>
      </div>
      <Button upperCase small onClick={onSetDetailsPressed}>
        {BTN_TXT.SET_ITEM_DETAILS}
      </Button>
    </div>
  )
}

export default SimilarItemsCard
