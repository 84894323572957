import { Bar } from '@ant-design/plots'
import { Select } from 'components/Select'
import { TOption } from 'components/Select/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getAggregatedSurveyResults } from './api'
import {
  IRFPStakeholder,
  RFPVotingResultsData,
  TRankedVendorFullData
} from './types'
import './styles.scss'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'redux/store/common/slice'
import { getIsLoading } from 'redux/store/common/getters'
import { getUniqueValues } from './CreateSurvey/helpers'

export const SurveyResults = ({ rfpID }: { rfpID: string }) => {
  const [stakeholders, setStakeholders] = useState<IRFPStakeholder[]>([])
  const [selectedStakeholder, setSelectedStakeHolder] = useState<string>('')
  const [surveyData, setSurveyData] = useState<RFPVotingResultsData['vote']>([])

  const dispatch = useDispatch()

  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    dispatch(setLoading(true))
    getAggregatedSurveyResults(rfpID)
      .then((res) => {
        if (res?.data.vote) {
          const stakeholder = res.data.vote.flatMap((vote) =>
            vote.ranked_vendors.map((vendorData) => vendorData.user)
          )
          const uniqueStakeholderIds = getUniqueValues(
            stakeholder.map((data) => data.uuid)
          )
          setStakeholders(
            uniqueStakeholderIds
              .map((id) => stakeholder.find((user) => user.uuid === id))
              .filter((item) => !!item) as IRFPStakeholder[]
          )
          setSurveyData(res.data.vote)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }, [dispatch, rfpID])

  const filteredSurveyData = useMemo(() => {
    if (selectedStakeholder && selectedStakeholder !== 'all') {
      return surveyData
        .map((item) => ({
          ...item,
          ranked_vendors: item.ranked_vendors.filter(
            (vendor) => vendor.user.uuid === selectedStakeholder
          )
        }))
        .filter((item) => !!item.ranked_vendors.length)
    }
    return surveyData
  }, [selectedStakeholder, surveyData])

  const surveyWithMergedAndSortedGrades = useMemo(
    () =>
      filteredSurveyData.map((survey) => ({
        ...survey,
        ranked_vendors: survey.ranked_vendors
          .reduce((acc, gradeData) => {
            const prevItem = acc.find(
              (item) => item.vendor.uuid === gradeData.vendor.uuid
            )
            if (prevItem) {
              return acc.map((item) => ({
                ...item,
                grade:
                  item.vendor.uuid === gradeData.vendor.uuid
                    ? item.grade + gradeData.grade
                    : item.grade
              }))
            }
            return [...acc, gradeData]
          }, [] as TRankedVendorFullData['ranked_vendors'])
          .sort((a, b) => b.grade - a.grade)
      })),
    [filteredSurveyData]
  )

  const returnSelectOptions = (arr: IRFPStakeholder[]): TOption[] => {
    if (arr?.length) {
      return [
        ...[{ label: `All responses (${arr.length})`, value: 'all' }],
        ...arr.map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.uuid
        }))
      ]
    }
    return []
  }

  const returnSurveyData = (arr: TRankedVendorFullData) => {
    if (arr?.ranked_vendors.length) {
      return arr.ranked_vendors.map((item) => ({
        vendor: item?.vendor?.name,
        grade: item?.grade
      }))
    }
    return []
  }

  const getConfig = useCallback(
    (data: TRankedVendorFullData) => ({
      data: returnSurveyData(data),
      yField: 'vendor',
      xField: 'grade',
      tooltip: false as const,
      yAxis: {
        grid: {
          line: {
            style: {
              stroke: '#F3F8FB',
              lineWidth: 1,
              lineDash: [1, 0],
              shadowColor: '#D0D7DC',
              shadowBlur: 1,
              shadowOffsetY: 44,
              cursor: 'pointer'
            }
          }
        },
        tickLine: {
          length: 0
        },
        subTickLine: {
          count: 1,
          length: 100,
          style: {
            stroke: '#F3F8FB',
            lineWidth: 2,
            lineDash: [1, 0],
            shadowColor: '#D0D7DC',
            shadowBlur: 0,
            shadowOffsetY: -0.5,
            cursor: 'pointer'
          }
        },
        label: {
          labelLine: true,
          position: 'start',
          style: {
            fill: '#323C43',
            fontSize: 14,
            testAlign: 'left'
          }
        }
      },
      label: {
        style: {
          width: '100%',
          fill: 'white',
          fontSize: 12,
          testAlign: 'left'
        }
      },
      color: '#3B85BA',
      minBarWidth: 20,
      maxBarWidth: 20,
      maxHeight: 160
    }),
    []
  )

  const getChartData = useCallback(
    (data: TRankedVendorFullData) => (
      <>
        <div>
          <Typography.Label className="mb-4">
            Contract Category
          </Typography.Label>
          <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            {data.category.name}
          </Typography.Body1>
        </div>
        <div
          className="survey-results__bar-container"
          style={{
            height: `calc(${
              getUniqueValues(
                data.ranked_vendors.map((item) => item.vendor.uuid)
              ).length
            } * 44px + 28px)`
          }}
        >
          <Bar className="survey-results__bar" {...getConfig(data)} />
        </div>
      </>
    ),
    [getConfig]
  )

  return (
    <>
      {isLoading ||
        (!surveyData.length ? (
          <div className="create-survey__content">
            <Typography.Body1>No responses yet.</Typography.Body1>
          </div>
        ) : (
          <div className="survey-results__container">
            <Select
              propsItem={{
                name: 'select'
              }}
              className="survey-results__select"
              options={returnSelectOptions(stakeholders)}
              propsSelect={{
                defaultActiveFirstOption: true,
                loading: !stakeholders?.length,
                onChange: setSelectedStakeHolder,
                value: selectedStakeholder,
                placeholder: `All responses (${stakeholders?.length})`
              }}
            />
            {surveyWithMergedAndSortedGrades.map((item) => getChartData(item))}
          </div>
        ))}
    </>
  )
}
