import { Dispatch, SetStateAction } from 'react'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { VendorItem } from './VendorItem/VendorItem'
import { Typography } from '../../../../../components/Typography'
import { TOptionsWithNameResponse } from '../../../../../constants'

interface IProps {
  vendorsList: TOptionsWithNameResponse[]
  setVendorsList: Dispatch<SetStateAction<TOptionsWithNameResponse[]>>
  isDisabled: boolean
}

export const VendorsDragList = ({
  vendorsList,
  setVendorsList,
  isDisabled
}: IProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e
    if (over && active.id !== over.id) {
      setVendorsList((items) => {
        const oldIndex = items.findIndex((item) => item.uuid === active.id)
        const newIndex = items.findIndex((item) => item.uuid === over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return (
    <div className="column">
      <Typography.Body1 className="mb-16">Most preferred</Typography.Body1>
      <div className="column gap-12">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={vendorsList.map((i) => i.uuid)}
            strategy={verticalListSortingStrategy}
          >
            {vendorsList.map((vendor, idx) => (
              <VendorItem
                key={vendor.uuid}
                positionNumber={idx + 1}
                vendor={vendor}
                dragDisabled={isDisabled}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <Typography.Body1 className="mt-16">Least preferred</Typography.Body1>
    </div>
  )
}
