export const RFP_TYPE_OPTIONS = [
  {
    label: 'Community RFP',
    value: 'community'
  },
  {
    label: 'Health System RFP',
    value: 'health_system'
  }
]
