import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  ResponseWithPagination,
  TOptionsWithNameResponse
} from 'constants/types'
import {
  IRFPSurveyCreateRequestPayload,
  IRFPSurveyPayload,
  IRFPVendorCCPayload,
  RFPStakeholderVoteResponseData,
  RFPVotingResultsData,
  TRFPSubmitSurveyVotingRequestData
} from 'features/RFP/RFPSurvey/types'

const fetcher = new Fetcher({})

export const getRfpVendorListRequestAsync = async (rfpID: string) =>
  fetcher.request<unknown, ResponseWithPagination<IRFPVendorCCPayload>>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.VENDOR}`,
    method: HTTP_METHODS.GET
  })

export const getRfpVendorCCListRequestAsync = async (
  rfpID: string,
  vendor: string
) =>
  fetcher.request<unknown, ResponseWithPagination<IRFPVendorCCPayload>>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CATEGORY}`,
    method: HTTP_METHODS.GET,
    params: { vendor }
  })

export const getSurveyRequestAsync = async (rfpID: string) =>
  fetcher.request<unknown, IRFPSurveyPayload>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}`,
    method: HTTP_METHODS.GET
  })

export const createOrUpdateSurveyRequestAsync = async (
  id: string,
  survey: IRFPSurveyCreateRequestPayload[]
) =>
  fetcher.request<{ survey: IRFPSurveyCreateRequestPayload[] }, unknown>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}`,
    method: HTTP_METHODS.POST,
    data: {
      survey
    }
  })

export const sendSurveyRequestAsync = async (id: string) =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}send/`,
    method: HTTP_METHODS.PATCH
  })

export const getAggregatedSurveyResults = async (rfpID: string) =>
  fetcher.request<unknown, RFPVotingResultsData>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}total_vote_result/`,
    method: HTTP_METHODS.GET
  })

export const submitSurveyVotingRequestAsync = async (
  id: string,
  data: TRFPSubmitSurveyVotingRequestData
) =>
  fetcher.request<TRFPSubmitSurveyVotingRequestData, unknown>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.STAKEHOLDER_VOTE}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getStakeholderVoteRequestAsync = async (id: string) =>
  fetcher.request<unknown, RFPStakeholderVoteResponseData>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.STAKEHOLDER_VOTE}`,
    method: HTTP_METHODS.GET
  })
