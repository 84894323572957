import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../../../components/Typography'
import { ReactComponent as Drag16 } from 'assets/svg/Drag16.svg'
import { VendorItemProps } from './types'
import './styles.scss'

export const VendorItem = ({
  vendor,
  positionNumber,
  dragDisabled
}: VendorItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: vendor.uuid })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div
      className="vendor-item full-width row align-center gap-16"
      ref={setNodeRef}
      style={style}
    >
      <Typography.Body1>{positionNumber}</Typography.Body1>
      <div className="vendor-item__content full-width p-16 row align-center space-between gap-16">
        <Typography.Body1
          className="full-width"
          weight={TYPOGRAPHY_WEIGHT.BOLD}
        >
          {vendor.name}
        </Typography.Body1>
        {!dragDisabled && <Drag16 {...attributes} {...listeners} />}
      </div>
    </div>
  )
}
