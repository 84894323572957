import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  IRFPSurveyCreateRequestPayload,
  IRFPVendorCCPayload,
  RFPStakeholderVoteResponseData,
  TRankedVendors
} from 'features/RFP/RFPSurvey/types'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useAbility } from '@casl/react'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { subject } from '@casl/ability'
import { RFP_STATUSES } from 'features/RFP/constants'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { idValueSettings, RFP_DEFAULT_VENDOR_LINE } from '../constants'
import { returnSelectOptions } from '../../../../../helper/common'
import { TOption } from '../../../../../components/Select/types'
import { TOptionsWithNameResponse } from '../../../../../constants'
import {
  parseSurveyToResultsFormat,
  vendorCCValuesToSurveyFormat
} from '../helpers'

type ContextProps = {
  state: {
    canCreateOrEditSurvey: boolean
    isSurveyInfoFilled: boolean
    localSelectedVendors: IRFPVendorCCPayload[]
    localAvailableVendors: IRFPVendorCCPayload[]
    localSelectedPickersValues: Partial<IRFPSurveyCreateRequestPayload>[]
    activeCategoryName: string
    isCancelButtonEnabled: boolean
    isPrevButtonEnabled: boolean
    isNextButtonEnabled: boolean
    isSubmitButtonEnabled: boolean
    activeSelectionList: TOptionsWithNameResponse[]
    activeCategoryIndex: number
    pagesCount: number
    fullListPreview: RFPStakeholderVoteResponseData
  }
  actions: {
    addVendorCCLine: VoidFunction
    removeVendorCCLine: (lineIndex: number) => void
    updateVendorValue: (lineIndex: number, vendor?: string) => void
    updateCCValue: (
      lineIndex: number,
      vendor: string,
      category?: string[]
    ) => void
    getSpecificVendorPickerOptions: (vendorId?: string) => TOption[]
    getSpecificCCPickerOptions: (vendorId?: string) => TOption[]
    handleCreateSurvey: VoidFunction
    handleSubmitSurvey: VoidFunction
    handelCancelSurveyVoting: VoidFunction
    handelCancelSurveyCreation: VoidFunction
    checkBeforeForwardSwitch: VoidFunction
    onCategorySwitch: (navTo: 'next' | 'prev') => void
    setActiveSelectionList: Dispatch<SetStateAction<TOptionsWithNameResponse[]>>
  }
}

const RFPCreateSurveyContext = createContext<ContextProps>({
  state: {} as ContextProps['state'],
  actions: {} as ContextProps['actions']
})

const RFPCreateSurveyContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const ability = useAbility<any>(AbilityContext)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const {
    createSurveyPopup,
    submitSurveyPopup,
    submitConfirmPopup,
    rankingUnchangedPopup
  } = useRFPDetailsPopupContext()

  const { data } = rfpDetailsContext.state
  const {
    vendors,
    contractCategories,
    selectedVendorValues,
    createdSurveyData,
    surveyResponse,
    isSurveySubmitted
  } = rfpSurveyContext.state
  const {
    submitSurveyVotingAsync,
    getRfpVendorCCListAsync,
    createSurveyAsync
  } = rfpSurveyContext.actions

  const defaultPickerValue = useMemo(
    () =>
      selectedVendorValues.length
        ? selectedVendorValues
        : [RFP_DEFAULT_VENDOR_LINE],
    [selectedVendorValues]
  )

  const [localSelectedPickersValues, _setLocalSelectedPickersValues] =
    useState<Partial<IRFPSurveyCreateRequestPayload>[]>(defaultPickerValue)

  const [activeCategoryIndex, _setActiveCategoryIndex] = useState<number>(0)
  const [activeSelectionList, setActiveSelectionList] = useState<
    TOptionsWithNameResponse[]
  >([])
  const [_savedCSelectionList, _setSavesSelectionList] = useState<{
    [key: number]: TOptionsWithNameResponse[]
  }>({})
  const [_initialSetup, _setInitialSetup] = useState<boolean>(false)

  const _parsedCreatedSurvey = useMemo(
    () =>
      createdSurveyData && parseSurveyToResultsFormat(createdSurveyData.survey),
    [createdSurveyData]
  )

  const _parsedQuestionData = useMemo(
    () => (isSurveySubmitted ? surveyResponse : _parsedCreatedSurvey),
    [_parsedCreatedSurvey, isSurveySubmitted, surveyResponse]
  )

  const localSelectedVendors = useMemo(() => {
    const selectedIds = localSelectedPickersValues
      .map((pickers) => pickers.vendor)
      .filter((vendor) => !!vendor)
    return vendors.filter((vendor) => selectedIds.includes(vendor.id))
  }, [localSelectedPickersValues, vendors])

  const fullListPreview = useMemo(
    () =>
      vendorCCValuesToSurveyFormat(
        localSelectedPickersValues,
        localSelectedVendors,
        contractCategories
      ),
    [contractCategories, localSelectedPickersValues, localSelectedVendors]
  )

  const pagesCount = useMemo(
    () => _parsedQuestionData?.vote.length || fullListPreview.vote.length || 0,
    [_parsedQuestionData?.vote.length, fullListPreview.vote.length]
  )

  const isCancelButtonEnabled = activeCategoryIndex === 0
  const isPrevButtonEnabled = activeCategoryIndex !== 0
  const isNextButtonEnabled = activeCategoryIndex < pagesCount - 1
  const isSubmitButtonEnabled = activeCategoryIndex === pagesCount - 1

  const _getActiveCategoryVendorsList = useCallback(
    (index: number) =>
      _parsedQuestionData &&
      [..._parsedQuestionData.vote[index].ranked_vendors]
        .sort((a, b) => b.grade - a.grade)
        .map((item) => item.vendor),
    [_parsedQuestionData]
  )

  const _updateActiveSelectionListBasedOnPage = useCallback(
    (index: number) => {
      const prevSavedListValue = _savedCSelectionList[index]
      const downloadedValue = _getActiveCategoryVendorsList(index)
      if (prevSavedListValue) {
        setActiveSelectionList(prevSavedListValue)
      } else if (downloadedValue?.length) {
        setActiveSelectionList(downloadedValue)
      }
    },
    [_getActiveCategoryVendorsList, _savedCSelectionList]
  )

  const onCategorySwitch = useCallback(
    (navTo: 'next' | 'prev') => {
      const newIndex =
        navTo === 'next' ? activeCategoryIndex + 1 : activeCategoryIndex - 1
      if (newIndex <= pagesCount - 1) {
        _setSavesSelectionList((prev) => ({
          ...prev,
          [activeCategoryIndex]:
            activeSelectionList as TOptionsWithNameResponse[]
        }))
        _setActiveCategoryIndex(newIndex)
        _updateActiveSelectionListBasedOnPage(newIndex)
      } else {
        submitConfirmPopup.actions.open()
      }
    },
    [
      activeCategoryIndex,
      pagesCount,
      _updateActiveSelectionListBasedOnPage,
      activeSelectionList,
      submitConfirmPopup.actions
    ]
  )

  // check if order been changed
  const _checkForPendingChanges = useCallback(() => {
    if (!isSurveySubmitted) {
      if (activeSelectionList.length !== 1) {
        const items = _getActiveCategoryVendorsList(activeCategoryIndex) ?? []
        return items.some(
          (vendor, idx) => activeSelectionList[idx].uuid !== vendor.uuid
        )
      }
    }
    return true
  }, [
    activeCategoryIndex,
    activeSelectionList,
    _getActiveCategoryVendorsList,
    isSurveySubmitted
  ])

  const checkBeforeForwardSwitch = useCallback(() => {
    if (_checkForPendingChanges()) {
      if (activeCategoryIndex === pagesCount - 1) {
        submitConfirmPopup.actions.open()
      } else {
        onCategorySwitch('next')
      }
    } else {
      rankingUnchangedPopup.actions.open()
    }
  }, [
    _checkForPendingChanges,
    activeCategoryIndex,
    pagesCount,
    submitConfirmPopup.actions,
    onCategorySwitch,
    rankingUnchangedPopup.actions
  ])

  const activeCategoryName = useMemo(
    () => _parsedQuestionData?.vote[activeCategoryIndex]?.category?.name ?? '',
    [_parsedQuestionData, activeCategoryIndex]
  )

  const isSurveyInfoFilled = useMemo(
    () =>
      localSelectedPickersValues.reduce((acc, val) => {
        if (!acc) return acc
        return !!val.vendor && !!val.category?.length
      }, true),
    [localSelectedPickersValues]
  )

  const localAvailableVendors = useMemo(
    () =>
      vendors
        ? vendors.filter(
            (vendor) =>
              !localSelectedVendors
                .map((vendor) => vendor.id)
                .includes(vendor.id)
          )
        : [],
    [localSelectedVendors, vendors]
  )

  const addVendorCCLine = useCallback(
    () =>
      _setLocalSelectedPickersValues((prev) => [
        ...prev,
        RFP_DEFAULT_VENDOR_LINE
      ]),
    []
  )

  const removeVendorCCLine = useCallback(
    (lineIndex: number) =>
      _setLocalSelectedPickersValues((prev) =>
        prev.filter((_, idx) => idx !== lineIndex)
      ),
    []
  )

  const updateVendorValue = useCallback(
    async (lineIndex: number, vendor?: string) => {
      _setLocalSelectedPickersValues((prev) =>
        prev.map((item, idx) => {
          let prevValue = { ...item }
          if (idx === lineIndex) {
            prevValue = { ...prevValue, vendor }
          }
          return prevValue
        })
      )
      if (vendor) {
        await getRfpVendorCCListAsync(vendor)
      }
    },
    [getRfpVendorCCListAsync]
  )

  const updateCCValue = useCallback(
    async (lineIndex: number, vendor: string, category?: string[]) =>
      _setLocalSelectedPickersValues((prev) =>
        prev.map((item, idx) => {
          let prevValue = { ...item }
          if (idx === lineIndex && item.vendor === vendor) {
            prevValue = { ...prevValue, category }
          }
          return prevValue
        })
      ),
    []
  )

  const getSpecificVendorPickerOptions = useCallback(
    (selectedVendorId?: string) => {
      const pickerOptions: IRFPVendorCCPayload[] = []
      if (selectedVendorId) {
        const selectedVendorOption = vendors.find(
          (vendor) => vendor.id === selectedVendorId
        )
        if (selectedVendorOption) {
          pickerOptions.push(selectedVendorOption)
        }
      }
      pickerOptions.push(...localAvailableVendors)
      return returnSelectOptions(pickerOptions, idValueSettings)
    },
    [localAvailableVendors, vendors]
  )

  const getSpecificCCPickerOptions = useCallback(
    (selectedVendorId?: string) => {
      const pickerOptions: IRFPVendorCCPayload[] = []
      if (selectedVendorId && contractCategories[selectedVendorId]) {
        pickerOptions.push(...contractCategories[selectedVendorId])
      }
      return returnSelectOptions(pickerOptions, idValueSettings)
    },
    [contractCategories]
  )

  const canCreateOrEditSurvey = useMemo(
    () =>
      ability.can(ACTIONS.CREATE, subject(SUBJECTS.SURVEY, { ...data })) &&
      data.status === RFP_STATUSES.PRICE_FILES_ANALYSIS,
    [ability, data]
  )

  const _updateLocalSelectedPickerValues = useCallback(() => {
    _setLocalSelectedPickersValues(defaultPickerValue)
  }, [defaultPickerValue])

  const _hasValidationError = useCallback(() => {
    const selectedCategoriesSet = new Set(
      localSelectedPickersValues.map((picker) => picker.category).flat()
    )

    return (
      localSelectedVendors.length <= 0 ||
      data.contract_categories.some((c) => !selectedCategoriesSet.has(c.uuid))
    )
  }, [
    data.contract_categories,
    localSelectedPickersValues,
    localSelectedVendors.length
  ])

  const handleCreateSurvey = useCallback(async () => {
    if (_hasValidationError()) {
      notification.error({ message: VALIDATION_MESSAGES.SM0042 })
      return
    }
    // checking that partial type is fully filled (not partial)
    if (isSurveyInfoFilled) {
      createSurveyAsync(
        localSelectedPickersValues as IRFPSurveyCreateRequestPayload[]
      )
    }
  }, [
    _hasValidationError,
    isSurveyInfoFilled,
    createSurveyAsync,
    localSelectedPickersValues
  ])

  const handleSubmitSurvey = useCallback(async () => {
    if (!_parsedQuestionData) return
    const payloadData: RFPStakeholderVoteResponseData = { vote: [] }
    _parsedQuestionData.vote.forEach((item, idx) => {
      const category = item.category
      const ranked_vendors: TRankedVendors[] = []
      if (idx !== pagesCount - 1) {
        ranked_vendors.push(
          ..._savedCSelectionList[idx].map((vendor, vendorIdx) => ({
            vendor,
            grade: _savedCSelectionList[idx]?.length - vendorIdx
          }))
        )
      } else {
        ranked_vendors.push(
          ...activeSelectionList.map((vendor, idx) => ({
            vendor,
            grade: activeSelectionList.length - idx
          }))
        )
      }
      payloadData.vote.push({
        category,
        ranked_vendors
      })
    })
    if (payloadData.vote.length) {
      submitSurveyVotingAsync(payloadData)
      submitConfirmPopup.actions.close()
    }
  }, [
    _parsedQuestionData,
    pagesCount,
    _savedCSelectionList,
    activeSelectionList,
    submitConfirmPopup.actions,
    submitSurveyVotingAsync
  ])

  const handelCancelSurveyVoting = useCallback(() => {
    _setSavesSelectionList({})
    _updateActiveSelectionListBasedOnPage(0)

    submitSurveyPopup.actions.close()
  }, [_updateActiveSelectionListBasedOnPage, submitSurveyPopup.actions])

  const handelCancelSurveyCreation = useCallback(() => {
    createSurveyPopup.actions.close()
    _updateLocalSelectedPickerValues()
  }, [_updateLocalSelectedPickerValues, createSurveyPopup.actions])

  useEffect(() => {
    _updateLocalSelectedPickerValues()
  }, [_updateLocalSelectedPickerValues])

  useEffect(() => {
    if (localSelectedVendors.length) {
      localSelectedVendors.forEach(async (vendor) => {
        // get CC called only in case when there's no data available
        await getRfpVendorCCListAsync(vendor.id)
      })
    }
  }, [getRfpVendorCCListAsync, localSelectedVendors])

  useEffect(() => {
    // initial setup of states
    if (!!surveyResponse && !!createdSurveyData && !_initialSetup) {
      _updateActiveSelectionListBasedOnPage(0)
      _setInitialSetup(true)
    }
  }, [
    _initialSetup,
    _updateActiveSelectionListBasedOnPage,
    createdSurveyData,
    surveyResponse
  ])

  const state = useMemo(
    () => ({
      canCreateOrEditSurvey,
      isSurveyInfoFilled,
      localSelectedVendors,
      localAvailableVendors,
      localSelectedPickersValues,
      activeCategoryName,
      isCancelButtonEnabled,
      isPrevButtonEnabled,
      isNextButtonEnabled,
      isSubmitButtonEnabled,
      activeSelectionList,
      activeCategoryIndex,
      pagesCount,
      fullListPreview
    }),
    [
      canCreateOrEditSurvey,
      isSurveyInfoFilled,
      localSelectedVendors,
      localAvailableVendors,
      localSelectedPickersValues,
      activeCategoryName,
      isCancelButtonEnabled,
      isPrevButtonEnabled,
      isNextButtonEnabled,
      isSubmitButtonEnabled,
      activeSelectionList,
      activeCategoryIndex,
      pagesCount,
      fullListPreview
    ]
  )

  const actions = useMemo(
    () => ({
      addVendorCCLine,
      removeVendorCCLine,
      updateVendorValue,
      updateCCValue,
      getSpecificVendorPickerOptions,
      getSpecificCCPickerOptions,
      handleCreateSurvey,
      handleSubmitSurvey,
      handelCancelSurveyVoting,
      handelCancelSurveyCreation,
      checkBeforeForwardSwitch,
      onCategorySwitch,
      setActiveSelectionList
    }),
    [
      addVendorCCLine,
      removeVendorCCLine,
      updateVendorValue,
      updateCCValue,
      getSpecificVendorPickerOptions,
      getSpecificCCPickerOptions,
      handelCancelSurveyVoting,
      handelCancelSurveyCreation,
      handleCreateSurvey,
      handleSubmitSurvey,
      checkBeforeForwardSwitch,
      onCategorySwitch,
      setActiveSelectionList
    ]
  )
  return (
    <RFPCreateSurveyContext.Provider value={{ state, actions }}>
      {children}
    </RFPCreateSurveyContext.Provider>
  )
}

export const useRFPCreateSurveyContext = () =>
  useContext(RFPCreateSurveyContext)

export default RFPCreateSurveyContextProvider
