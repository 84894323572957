import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TAwardVendorsRequestData,
  TResponsibleUser,
  TRFPBiddingData,
  TRFPCreateTimelineRequest,
  TRFPCreateTimelineRequestData,
  TRFPCreateTimelineResponseData,
  TRFPDetailsResponse,
  TRFPDocument,
  TRFPUpdateByVendorRequest,
  TRFPUploadFilesRequest,
  TUpdateResponsibleUsersRequestData,
  TUpdateResponsibleUsersResponseData,
  TRFPResponse,
  TRFPUpdateRequest,
  TFURequest
} from './types'
import { ResponseWithPagination } from 'constants/types'
import { ICCList } from '../../../components/UnsetCategoriesModal/types'

const fetcher = new Fetcher({})

export const getRfpDetails = async (id: string) =>
  fetcher.request<unknown, TRFPDetailsResponse>({
    url: `${API_DICTIONARY.RFP}${id}/`,
    method: HTTP_METHODS.GET
  })

export const createRFP = async (data: any) =>
  fetcher.request<unknown, TRFPResponse>({
    url: API_DICTIONARY.RFP,
    method: HTTP_METHODS.POST,
    successMessage: 'RFP has been successfully added.',
    data
  })

export const updateRFP: TRFPUpdateRequest = async (id, data, successMessage) =>
  fetcher.request<unknown, TRFPResponse>({
    url: `${API_DICTIONARY.RFP}${id}/`,
    method: HTTP_METHODS.PATCH,
    successMessage,
    data
  })

export const deleteFacilityUsersOnRFP: TFURequest = async (
  id,
  data,
  successMessage
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_HS_USERS}`,
    method: HTTP_METHODS.DELETE,
    successMessage,
    data
  })

export const addFacilityUsersOnRFP: TFURequest = async (
  id,
  data,
  successMessage
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_HS_USERS}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })

export const updateVendorUsers: TRFPUpdateRequest = async (
  id,
  data,
  successMessage
) =>
  fetcher.request<unknown, TRFPResponse>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_VENDOR_TEAM}`,
    method: HTTP_METHODS.PATCH,
    successMessage,
    data
  })

export const cancelRFP = async (id: string) =>
  fetcher.request<unknown, TRFPResponse>({
    url: `${API_DICTIONARY.RFP}${id}/`,
    method: HTTP_METHODS.DELETE
  })

export const uploadFiles: TRFPUploadFilesRequest = async (data) =>
  fetcher.request<unknown, TRFPDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.RFP_DEFAULT_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const createRFPTimelineAsync: TRFPCreateTimelineRequest = async (
  id,
  data
) =>
  fetcher.request<
    TRFPCreateTimelineRequestData,
    TRFPCreateTimelineResponseData
  >({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.TIMELINES}`,
    method: HTTP_METHODS.POST,
    // successMessage: 'RFP has been successfully added.',
    data
  })

export const uploadVendorFiles: TRFPUploadFilesRequest = async (data) =>
  fetcher.request<unknown, TRFPDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.RFP_VENDOR_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const awardVendorsRequestAsync = async (
  id: string,
  data: TAwardVendorsRequestData
) =>
  fetcher.request<{ awarded_vendors: TAwardVendorsRequestData }, TRFPResponse>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.ARCHIVE}`,
    method: HTTP_METHODS.POST,
    data: { awarded_vendors: data }
  })

export const updateRFPByVendor: TRFPUpdateByVendorRequest = async (
  id,
  data,
  successMessage
) =>
  fetcher.request<unknown, TRFPDocument>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.VENDOR_BIDDING}`,
    method: HTTP_METHODS.PATCH,
    successMessage,
    data
  })

export const getBiddingData = async (id: string) =>
  fetcher.request<unknown, TRFPBiddingData>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.VENDOR_BIDDING}`,
    method: HTTP_METHODS.GET
  })

export const startVendorsSelectionRequestAsync = async (
  id: string,
  data: Partial<TRFPDetailsResponse>
) =>
  fetcher.request<unknown, void>({
    url: `${API_DICTIONARY.RFP}${id}/`,
    method: HTTP_METHODS.PATCH,
    data
  })

export const updateResponsibleUsersRequestAsync = async (
  id: string,
  data: TUpdateResponsibleUsersRequestData,
  successMessage
) =>
  fetcher.request<
    TUpdateResponsibleUsersRequestData,
    TUpdateResponsibleUsersResponseData
  >({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RESPONSIBLE_USERS}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })

export const updateCommonsResponsibleUsersRequestAsync = async (
  id: string,
  data: TUpdateResponsibleUsersRequestData,
  successMessage
) =>
  fetcher.request<
    TUpdateResponsibleUsersRequestData,
    TUpdateResponsibleUsersResponseData
  >({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.COMMONS_REASSIGNMENT}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })

export const getAvailableResponsibleUsersRequestAsync = async (id: string) =>
  fetcher.request<unknown, TResponsibleUser[]>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RESPONSIBLE_USERS}`,
    method: HTTP_METHODS.GET
  })
export const getAvailableResponsibleCommonsUsersRequestAsync = async (
  id: string
) =>
  fetcher.request<unknown, ResponseWithPagination<TResponsibleUser>>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.OPTIONS}${API_DICTIONARY.COMMONS_RESPONSIBLE}`,
    method: HTTP_METHODS.GET
  })

export const getUnsetRfpCCList = async (id: string, data: any) =>
  fetcher.request<unknown, ResponseWithPagination<ICCList>>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.UNSET_CATEGORIES}`,
    method: HTTP_METHODS.GET,
    ...data
  })
